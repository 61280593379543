import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import App from './App';
import { store, history } from './redux/store';
import * as serviceWorker from './serviceWorker';
import './i18n';
const socket = { a: 1 };


ReactDOM.render((
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Route path="/" component={App} />
        </ConnectedRouter>
    </Provider >
)
    , document.getElementById('root')
);

serviceWorker.unregister();
