export const GENERATESLASH = 'GENERATESLASH';
export const GENERATEINFO = 'GENERATEINFO';
export const LIST_LINK = 'LIST_LINK';
export const SHOW_LINK = 'SHOW_LINK';
export const DELETE_LINKS = 'DELETE_LINKS';
export const DELETE_LINK = 'DELETE_LINK';
export const CREATE_LINK = 'CREATE_LINK';
export const UPDATE_LINK = 'UPDATE_LINK';
export const SEARCH_LINK = 'SEARCH_LINK';
export const RELOAD_LINK = 'RELOAD_LINK';
export const EXPORT_LINK = 'EXPORT_LINK';
export const IMPORT_LINK = 'IMPORT_LINK';
export const RESET_LINK = 'RESET_LINK';
export const UPLOAD_FILE = 'UPLOAD_FILE';