import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../../i18n';
import NoData from '../../components/NoData';
import moment from 'moment';
import LadiPagination from '../../components/LadiPagination';
import tagActions from '../../redux/futures/tag/actions';
import ModalCreateTag from './components/ModalCreateTag';
import ModalDeleteTag from './components/ModalDeleteTag';
import FormMultiAction from './components/FormMultiAction';


const TagV3 = () => {
     const dispatch = useDispatch();
     const tags = useSelector(state => state.tag.tags);
     const totalRecord = useSelector(state => state.tag.totalRecord);

     const [selectedIds, setSelectedIds] = useState([]);
     const [checkAll, setCheckAll] = useState(false);

     const [keyWord, setKeyWord] = useState('')

     const [conditions, setConditions] = useState({
          keyword: "",
          limit: 10,
          page: 1,
          sort: {},
     });

     const listTags = () => {
          dispatch(tagActions.list(conditions));
     }

     const onSelectedLimit = (limit) => {
          setConditions({ ...conditions, limit: limit });
     }

     const onPageChange = (pageNumber) => {
          setConditions({ ...conditions, page: pageNumber });
     }

     const eventCheckMulti = (e) => {
          const ids = [];
          if (e.target.checked) {
               const items = document.getElementsByClassName('checkbox-element');
               for (let i = 0; i < items.length; i++) {
                    ids.push(items[i].value);
               }
               setSelectedIds(ids);
               setCheckAll(true);
          } else {
               setCheckAll(false);
          }
          setSelectedIds(ids);
     };

     const onCheckBoxItem = (e) => {
          const id = e.target.value;
          if (e.target.checked) {
               setSelectedIds([...selectedIds, ...[id]]);
          } else {
               const ids = selectedIds.filter((item) => item !== id);
               setSelectedIds(ids);
          }
     };

     const resetSelectedIds = () => {
          setSelectedIds([]);
          setCheckAll(false);
     }

     const [itemTag, setItemTag] = useState()
     const [type, setType] = useState()

     const onCreate = () => {
          window.LadiUI.showModal("createTag");
          setType('CREATE');
     }


     const onEdit = (item) => {
          if (!item) { return }
          setItemTag(item);
          setType('UPDATE');
          window.LadiUI.showModal("createTag")
     }

     const onDelete = (item) => {
          if (!item) { return }
          setItemTag(item)
          window.LadiUI.showModal("modalDelete")
     }

     useEffect(() => {
          listTags();
     }, [conditions])

     const renderTagItem = (item) => {
          return <tr className="ladiui table-vertical main" key={item._id}>
               <td scope="row">
                    <input
                         type="checkbox"
                         className="ladiui checkbox size-checkbox checkbox-element form-check-input-checkbox"
                         id="is-check"
                         name="checkbox"
                         checked={selectedIds.includes(item._id)}
                         value={item._id}
                         onChange={onCheckBoxItem}
                    />
               </td>
               <td className="ladiui name-landingpage">
                    <div className='ladiui title-page'>
                         {item && item.name ? item.name : ''}
                    </div>
               </td>
               <td><span className="ladiui title-page">{item.total_link}</span></td>
               <td className="ladiui title-page">{moment(item.created_at).format('L')} {moment(item.created_at).format('LT')}</td>
               <td className="ladiui title-page">{moment(item.updated_at).format('L')} {moment(item.updated_at).format('LT')}</td>

               <td className="ladiui-dropdown text-right">
                    <div className="ladiui btn-group">
                         <div className="ladiui dropdown">
                              <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                                   <i className="ladiui icon icon-ldp-dot"></i>
                              </button>
                              <ul className="ladiui dropdown-menu custom-dropdown-menu">
                                   <li onClick={() => onEdit(item)}>
                                        <div className="ladiui dropdown-item">
                                             <i className="ladiui icon icon-ldp-setting-builder"></i> {i18n.t("Chỉnh sửa")}
                                        </div>
                                   </li>
                                   <li onClick={() => onDelete(item)}>
                                        <div className="ladiui dropdown-item lastChild btn-open-modal">
                                             <i className="ladiui icon text-color icon-ldp-delete"></i> {i18n.t("Xoá")}
                                        </div>
                                   </li>
                              </ul>
                         </div>
                    </div>
               </td>
          </tr>
     }

     return (
          <div className="ladiui content-main-full">
               <div className="ladiui content-main-title flex-row">
                    <div className="ladiui main-title">{i18n.t("Danh sách Tag")}</div>
                    <button id="button-domain-create" type="button" className="ladiui btn-open-modal btn btn-primary" onClick={() => onCreate()}>
                         <img className="ladiui btn-custom-img" src='https://w.ladicdn.com/ladiui/ladipage/icon-ldp-add.svg' alt="" />
                         <div className="ladiui btn-custom-text">{i18n.t("Tạo Tag")}</div>
                    </button>
               </div>

               <div className="ladiui content-main-tool flex-row">
                    <div className="ladiui search-group dropdown">
                         <input
                              autoComplete='off'
                              className="ladiui search-field form-control"
                              style={{ minWidth: 270 }}
                              aria-expanded={false}
                              name='keyword'
                              placeholder={i18n.t("Tìm kiếm")}
                              onChange={(e) => {
                                   setKeyWord(e.target.value);
                                   if (e.target.value === '') {
                                        setConditions({ ...conditions, keyword: '', page: 1 })
                                   }
                              }}
                              onKeyDown={(e) => { if (e.key === 'Enter') setConditions({ ...conditions, keyword: keyWord, page: 1 }) }}
                         />
                         <i className="ladiui icon icon-search" onClick={() => setConditions({ ...conditions, keyword: keyWord, page: 1 })}></i>
                    </div>
               </div>

               <table className="ladiui table text-left">
                    <thead>
                         <tr className="ladiui table-vertical header">
                              <th className="ladiui col-first" scope="col">
                                   <input
                                        data-toggle="checkbox"
                                        type="checkbox"
                                        className="ladiui checkbox-all size-checkbox form-check-input-checkbox"
                                        id="checkAllItem"
                                        checked={checkAll}
                                        onChange={eventCheckMulti}
                                   />
                              </th>
                              <th className="ladiui col-one-custom" scope="col">
                                   {
                                        selectedIds.length > 0 ?
                                             <FormMultiAction ids={selectedIds} callback={listTags} status={conditions.is_delete} />
                                             : i18n.t('Tag')
                                   }
                              </th>
                              <th className="ladiui col-tow" scope="col">{i18n.t("Số Link")}</th>
                              <th className="ladiui col-three" scope="col">{i18n.t("Ngày tạo")}</th>
                              <th className="ladiui col-three" scope="col">{i18n.t("Ngày cập nhật")}</th>
                              <th className="ladiui dot custom" scope="col"></th>
                         </tr>
                    </thead>
                    <tbody>{
                         tags && tags.length > 0 ?
                              tags.map(item => (
                                   renderTagItem(item)
                              ))
                              :
                              <NoData column={7} status={i18n.t("Không tìm thấy Tag nào")} />
                    }
                    </tbody>
               </table>

               {totalRecord > 0 &&
                    <LadiPagination
                         conditionsProp={conditions}
                         listName={i18n.t("Tag")}
                         items={tags}
                         total={totalRecord}
                         limitAction={onSelectedLimit}
                         pageAction={onPageChange} />
               }
               <ModalCreateTag callback={listTags} itemTag={itemTag} type={type} />
               <ModalDeleteTag callback={listTags} itemTag={itemTag} selectedIds={selectedIds} resetSelectedIds={resetSelectedIds} />
          </div>
     )
}

export default TagV3