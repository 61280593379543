import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const getUserInfo = () => ({
    type: types.GET_USER_INFO,
    meta: {
        endpoint: endpoint.STORE_INFO,
        method: REQUEST_METHOD.POST,
        hasAuth: true,
    },
});

const update = (data) => ({
    type: types.UPDATE_STORE,
    meta: {
        endpoint: endpoint.STORE_UPDATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
    },
});

export default {
    getUserInfo,
    update,
};
