String.prototype.replaceAll = function (target, replacement) {
     return this.valueOf().split(target).join(replacement);
};
String.prototype.format = function () {
     var formatted = this;
     for (var i = 0; i < arguments.length; i++) {
          var regexp = new RegExp('\\{' + i + '\\}', 'gi');
          formatted = formatted.replace(regexp, arguments[i]);
     }
     return formatted.valueOf();
};
Array.prototype.insert = function (index, item) {
     this.splice(index, 0, item);
};
Array.prototype.except = function (arr) {
     var instance = this;

     if (Array.isArray(arr)) {
          arr.forEach(function (value) {
               var index = instance.indexOf(value);
               if (index != -1) {
                    instance.splice(index, 1);
               }
          });
     }

     return instance;
};

window.LadiPageCustomV3 = function () { };

window.LadiPageCustomV3.prototype.customDropdown = function (e) {
     var button = e.nativeEvent.target;
     var rect = button.getBoundingClientRect();
     var topheight = rect.top + rect.height;
     var dropdown = window.LadiUI.findAncestor(button, 'dropdown');
     var dropdown_menu = dropdown.getElementsByClassName('dropdown-menu')[0];

     var scrollY = 0;
     var bodyHeight = window.innerHeight;

     topheight += dropdown_menu.clientHeight;
     topheight += scrollY;

     if (topheight > bodyHeight) {
          dropdown_menu.classList.add('top');
          dropdown_menu.style.setProperty('margin-top', -rect.height + 'px');

          var topbarTop = document.getElementById('topbar-top');
          if (topbarTop.clientHeight > rect.top - dropdown_menu.clientHeight) {
               dropdown_menu.classList.remove('top');
               dropdown_menu.style.removeProperty('margin-top');
          }
     } else {
          dropdown_menu.classList.remove('top');
          dropdown_menu.style.removeProperty('margin-top');
     }
};

window.LadiPageCustom = new window.LadiPageCustomV3();