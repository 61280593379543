import {
    SIGNIN_SUCCESS
} from '../futures/auth/types';

const notificationMiddleware = store => next => action => {
    if (action.type === SIGNIN_SUCCESS) {
    }
    next(action);
};

export default notificationMiddleware;
