import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../../i18n';
import linkActions from '../../redux/futures/link/actions';
import tagActions from '../../redux/futures/tag/actions';
import domainActions from '../../redux/futures/domain/actions';
import NoData from '../../components/NoData';
import moment from 'moment';
import LadiPagination from '../../components/LadiPagination';
import ModalCreateLink from './components/ModalCreateLink';
import ModalUpdateLink from './components/ModalUpdateLink';
import ModalQrCode from './components/ModalQrCode';
import ModalDeleteLink from './components/ModalDeleteLink';
import FormMultiAction from './components/FormMultiAction';

const LinkV3 = () => {
     const dispatch = useDispatch();
     const links = useSelector(state => state.link.links);
     const tags = useSelector(state => state.tag.tags);
     const domains = useSelector(state => state.domain.domains);
     const totalRecord = useSelector(state => state.link.totalRecord);
     const totalRecordTag = useSelector(state => state.tag.totalRecord);
     const [tagSelected, setTagSelected] = useState([]);

     const [selectedIds, setSelectedIds] = useState([]);
     const [checkAll, setCheckAll] = useState(false);

     const [keyWord, setKeyWord] = useState('');

     const [conditions, setConditions] = useState({
          domain: "",
          keyword: "",
          limit: 10,
          page: 1,
          sort: {},
     });

     const [payLoadTag, setPayLoadTag] = useState({
          keyword: "",
          page: 1,
          limit: 100,
     });

     const listLink = () => {
          dispatch(linkActions.list(conditions));
          resetSelectedIds();
     }

     const listTags = () => {
          dispatch(tagActions.list(payLoadTag));
     }

     const listDomain = () => {

          dispatch(domainActions.list())
     }

     const onSelectedLimit = (limit) => {
          setConditions({ ...conditions, limit: limit });
     }

     const onPageChange = (pageNumber) => {
          setConditions({ ...conditions, page: pageNumber });
     }

     const eventCheckMulti = (e) => {
          const ids = [];
          if (e.target.checked) {
               const items = document.getElementsByClassName('checkbox-element');
               for (let i = 0; i < items.length; i++) {
                    ids.push(items[i].value);
               }
               setSelectedIds(ids);
               setCheckAll(true);
          } else {
               setCheckAll(false);
          }
          setSelectedIds(ids);
     };

     const onCheckBoxItem = (e) => {
          const id = e.target.value;
          if (e.target.checked) {
               setSelectedIds([...selectedIds, ...[id]]);
          } else {
               const ids = selectedIds.filter((item) => item !== id);
               setSelectedIds(ids);
          }
     };

     const resetSelectedIds = () => {
          setSelectedIds([]);
          setCheckAll(false);
     }

     const copyClipboard = (item) => {
          let text;

          if (item && item.https == true && item.path) {
               text = `http://${item.domain}/${item.path}`
          } if (item && item.https == false && item.path) {
               text = `http://${item.domain}/${item.path}`
          } else {
               return;
          }

          navigator.clipboard.writeText(text);
          window.LadiUI.toast('success', 'Thông báo', i18n.t("Thành công"), 5000, 'bottom-left');
     };

     const handleScroll = event => {
          let scrollTop = event.currentTarget.scrollTop;
          let offsetHeight = event.currentTarget.offsetHeight;
          let scrollHeight = event.currentTarget.scrollHeight;

          if (offsetHeight + scrollTop + 1 >= scrollHeight) {
               if (payLoadTag.limit < totalRecordTag) {
                    setPayLoadTag({ ...payLoadTag, limit: (payLoadTag.limit + 20) });
               }
          }
     };

     const onCreateLink = () => {
          window.LadiUI.showModal('modalCreateLink');
     }

     const [itemLink, setItemLink] = useState()

     const onDelete = (item) => {
          if (!item) { return }
          setItemLink(item)
          window.LadiUI.showModal("modalDelete")
     }

     const onEdit = (item) => {
          if (!item) { return }
          setItemLink(item);
          let _tags = item.tags || [];
          const _selectedTags = [];
          if (tags && tags.length > 0) {
               for (let i = 0; i < tags.length; i++) {
                    if (_tags.indexOf(tags[i]._id) >= 0)
                         _selectedTags.push(tags[i]);
               }
          }
          setTagSelected(_selectedTags);

          window.LadiUI.showModal("ModalUpdateLink")
     }

     const QRCode = (item) => {
          if(item){
               setItemLink(item)
          }
          window.LadiUI.showModal("modalQR")
     };

     useEffect(() => {
          listLink();
     }, [conditions])

     useEffect(() => {
          listTags();
     }, [payLoadTag])

     useEffect(() => {
          listDomain();
     }, [])


     const renderLinkItem = (item) => {
          return <tr className="ladiui table-vertical main" key={item._id}>
               <td scope="row">
                    <input
                         type="checkbox"
                         className="ladiui checkbox size-checkbox checkbox-element form-check-input-checkbox"
                         id="is-check"
                         name="checkbox"
                         checked={selectedIds.includes(item._id)}
                         value={item._id}
                         onChange={onCheckBoxItem}
                    />
               </td>
               <td className="ladiui name-landingpage">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: '16px', width: '100%' }}>
                         <div>
                              <div className='ladiui title-page'>
                                   <a
                                        className='ladiui title item-ladipage hover-primary'
                                        href={item && item.https == true && item.path ? `http://${item.domain}/${item.path}` : `http://${item.domain}/${item.path}`}
                                        target="_blank"
                                   >
                                        {item && item.https == true && item.path ? `http://${item.domain}/${item.path}` : `http://${item.domain}/${item.path}`}
                                   </a>
                              </div>
                              <div style={{ marginTop: '4px', display: 'flex', alignItems: 'center' }}>
                                   <img src={item && item.origin_domain ? `https://www.google.com/s2/favicons?domain=${item.origin_domain}` : ''} style={{ width: '16px', height: '16px' }} />
                                   <div className='ladiui title item-ladipage' style={{ marginLeft: '8px' }}>{item && item.title ? item.title : ''}</div>
                              </div>
                         </div>
                         <div style={{ display: 'flex' }}>
                              <div className='ladiui tooltip'>
                                   <img
                                        className='ladiui icon-hover-copy tooltip'
                                        src='https://w.ladicdn.com/v2/source/builder/svg/qr_code.svg'
                                        style={{ width: '16px', height: '16px', marginRight: '16px' }}
                                        onClick={() => QRCode(item)}
                                   />
                                   <span className="tooltiptext top" tooltiptext={i18n.t("QR Code")}></span>
                              </div>
                              <div className='ladiui tooltip'>
                                   <img
                                        className='ladiui icon-hover-copy tooltip'
                                        src='https://w.ladicdn.com/ladiui/icons/ldicon-copy.svg'
                                        style={{ width: '16px', height: '16px', marginRight: '16px' }}
                                        onClick={() => copyClipboard(item)}
                                   />
                                   <span className="tooltiptext top" tooltiptext={i18n.t("Copy")}></span>
                              </div>
                         </div>

                    </div>
               </td>
               <td><span className="ladiui title-page">{item.click}</span></td>
               <td className="ladiui title-page">{moment(item.created_at).format('L')} {moment(item.created_at).format('LT')}</td>
               <td className="ladiui-dropdown text-right">
                    <div className="ladiui btn-group">
                         <div className="ladiui dropdown">
                              <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle">
                                   <i className="ladiui icon icon-ldp-dot"></i>
                              </button>
                              <ul className="ladiui dropdown-menu custom-dropdown-menu">
                                   <li onClick={() => onEdit(item)}>
                                        <a className="ladiui dropdown-item">
                                             <i className="ladiui icon icon-ldp-setting-builder"></i> {i18n.t("Chỉnh sửa")}
                                        </a>
                                   </li>
                                   <li onClick={() => onDelete(item)}>
                                        <a className="ladiui dropdown-item lastChild btn-open-modal">
                                             <i className="ladiui icon text-color icon-ldp-delete"></i> {i18n.t("Xoá")}
                                        </a>
                                   </li>
                              </ul>
                         </div>
                    </div>
               </td>
          </tr>
     }

     return (
          <div className="ladiui content-main-full">
               <div className="ladiui content-main-title flex-row">
                    <div className="ladiui main-title">{i18n.t("Danh sách link")}</div>
                    <button id="button-domain-create" type="button" className="ladiui btn-open-modal btn btn-primary" onClick={() => onCreateLink()}>
                         <img className="ladiui btn-custom-img" src='https://w.ladicdn.com/ladiui/ladipage/icon-ldp-add.svg' alt="" />
                         <div className="ladiui btn-custom-text">{i18n.t("Tạo link mới")}</div>
                    </button>
               </div>

               <div className="ladiui content-main-tool flex-row">
                    <div className="ladiui search-group dropdown">
                         <input
                              autoComplete='off'
                              className="ladiui search-field form-control"
                              style={{ minWidth: 270 }}
                              aria-expanded={false}
                              name='keyword'
                              placeholder={i18n.t("Tìm kiếm")}
                              onChange={(e) => {
                                   setKeyWord(e.target.value);
                                   if (e.target.value === '') {
                                        setConditions({ ...conditions, keyword: '', page: 1 })
                                   }
                              }}
                              onKeyDown={(e) => { if (e.key === 'Enter') setConditions({ ...conditions, keyword: keyWord, page: 1 }) }}
                         />
                         <i className="ladiui icon icon-search" onClick={() => setConditions({ ...conditions, keyword: keyWord, page: 1 })}></i>
                    </div>
                    <div className="ladiui btn-group custom-btn-list-landingpage">
                         <div className="ladiui dropdown">
                              <button className="ladiui btn btn-outline-light dropdown-toggle custom-btn-list-landingpage select-dropdown" data-toggle="dropdown">
                                   <i></i>
                                   <span className="ladiui dropdown-text">{i18n.t("Chọn tên miền")}</span>
                              </button>
                              <ul className="ladiui dropdown-menu custom-btn-list-landingpage" style={{ minWidth: "max-content", overflowY: "scroll", maxHeight: "250px" }}>
                                   <li><a className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, domain: '', page: 1 })}>{i18n.t("Tất cả")}</a></li>
                                   <li><a className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, domain: 'ldp.to', page: 1 })}>{i18n.t("ldp.to")}</a></li>
                                   <li><a className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, domain: 'ldf.to', page: 1 })}>{i18n.t("ldf.to")}</a></li>
                                   <li><a className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, domain: 'ldp.ink', page: 1 })}>ldp.ink</a></li>
                                   {
                                        domains &&  domains.length > 0
                                        ?
                                            domains.map((item, index) => {
                                                 if (item.is_verified) {
                                                      return (
                                                          <li key={index}><a className="ladiui dropdown-item"
                                                                             onClick={() => setConditions({
                                                                                  ...conditions,
                                                                                  domain: item.domain,
                                                                                  page: 1
                                                                             })}>{item.domain}</a></li>
                                                      )
                                                 }
                                            }) : ""
                                   }
                              </ul>
                         </div>
                    </div>

                    <div className="ladiui btn-group custom-settings">
                         <div className="ladiui dropdown">
                              <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" id="dropdownMenuButton3" data-toggle="dropdown" >
                                   <i></i>
                                   <span className="ladiui dropdown-text">{i18n.t("Chọn Tag")}</span>
                              </button>
                              <ul className="ladiui dropdown-menu custom-setting" onScroll={handleScroll}>
                                   <li onClick={() => setConditions({ ...conditions, tags: "", page: 1 })}><div className="ladiui dropdown-item">{i18n.t("Tất cả")}</div></li>
                                   {
                                        tags && tags.length > 0 ?
                                             tags.map((item, index) => (
                                                  <li key={index} onClick={() => setConditions({ ...conditions, tags: item._id, page: 1 })}><div className="ladiui dropdown-item">{item.name}</div></li>
                                             )) : null
                                   }
                              </ul>
                         </div>
                    </div>
               </div>

               <table className="ladiui table text-left">
                    <thead>
                         <tr className="ladiui table-vertical header">
                              <th className="ladiui col-first" scope="col">
                                   <input
                                        data-toggle="checkbox"
                                        type="checkbox"
                                        className="ladiui checkbox-all size-checkbox form-check-input-checkbox"
                                        id="checkAllItem"
                                        checked={checkAll}
                                        onChange={eventCheckMulti}
                                   />
                              </th>
                              <th className="ladiui col-one" scope="col">
                                   {
                                        selectedIds.length > 0 ?
                                             <FormMultiAction ids={selectedIds} callback={listLink} status={conditions.is_delete} />
                                             : i18n.t("Đường dẫn")
                                   }
                              </th>
                              <th className="ladiui col-tow" scope="col">{i18n.t("Lượt Click")}</th>
                              <th className="ladiui col-three" scope="col">{i18n.t("Ngày tạo")}</th>
                              <th className="ladiui dot custom" scope="col"></th>
                         </tr>
                    </thead>
                    <tbody>{
                         links && links.length > 0 ?
                              links.map(item => (
                                   renderLinkItem(item)
                              ))
                              :
                              <NoData column={7} status={i18n.t("Không tìm thấy Link nào")} />
                    }
                    </tbody>
               </table>

               {totalRecord > 0 &&
                    <LadiPagination
                         conditionsProp={conditions}
                         listName={i18n.t("Link")}
                         items={links}
                         total={totalRecord}
                         limitAction={onSelectedLimit}
                         pageAction={onPageChange} />
               }

               <ModalCreateLink
                    tags={tags}
                    callback={listLink}
                    defaultDomain={'ldp.ink'}
                    itemLink={itemLink}
               />
               <ModalUpdateLink
                    tags={tags}
                    setTagSelected={setTagSelected}
                    tagSelected={tagSelected}
                    callback={listLink}
                    itemLink={itemLink}
               />
               <ModalDeleteLink
                    callback={listLink}
                    itemLink={itemLink}
                    selectedIds={selectedIds}
                    resetSelectedIds={resetSelectedIds} />
               <ModalQrCode 
                 itemLink={itemLink}
               />
          </div>
     )
}

export default LinkV3