import React from 'react';

class LoadingScene extends React.Component {
    
    render() {
        const { blur } = this.props;
        return (
            <div className={`builder-loading ${blur ? 'builder-loading-blur' : ''}`} style={{ backgroundImage: `url('https://w.ladicdn.com/ladiui/ladipage-loading.gif')` }}>
            </div>
        );
    };
}

export default LoadingScene;