import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import i18n from '../../../i18n';
import domainActions from '../../../redux/futures/domain/actions';

const ModalUpdate = ({ currentDomain, callback }) => {
    const dispatch = useDispatch();
    const [googleTracking, setGoogleTracking] = useState();
    const [facebookTracking, setFacebookTracking] = useState();
    const [dataDomain, setDataDomain] = useState();
    const onUpdate = () => {
        dispatch(domainActions.update(dataDomain, callback));
    }
    useEffect(() => {
        if (currentDomain && currentDomain.tracking) {
            try {
                const tracking = JSON.parse(currentDomain.tracking);
                setFacebookTracking(tracking.facebook);
                setGoogleTracking(tracking.google);
                setDataDomain({
                    id: currentDomain._id,
                    domain: currentDomain ? currentDomain.domain : '',
                    tracking: JSON.stringify({
                        "facebook": facebookTracking,
                        "google": googleTracking,
                    }),
                    redirect301: false
                });

            } catch (err) {
                setFacebookTracking("");
                setGoogleTracking("");
            }
        }
    }, [currentDomain])

    useEffect(() => {
        setDataDomain({
            id: currentDomain ? currentDomain._id : '',
            domain: currentDomain ? currentDomain.domain : '',
            tracking: JSON.stringify({
                "facebook": facebookTracking,
                "google": googleTracking,
            }),
            redirect301: false
        });
    }, [googleTracking, facebookTracking])

    return (
        <div className="ladiui modal" id="modalUpdateDomain" >
            <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
                <div className="ladiui modal-content custom-ldp">
                    <div className="ladiui modal-header custom-ldp">
                        <div className="ladiui title-text-form-lever1">{i18n.t('Sửa tên miền')}</div>
                        <button type="button" className="ladiui modal-close" data-dismiss="modal" data-target="modalUpdateDomain">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className='ladiui flex-row custom-flex-switch'>
                        <div className="ladiui form-group">
                            <div className='ladiui '>
                                <label className="ladiui title-text-form-lever2">{i18n.t("Tên miền")}</label>
                                <div className="ladiui btn-group">
                                    <input
                                        id="pathInput2"
                                        autoComplete="off"
                                        name="domain2"
                                        type="text"
                                        className="ladiui form-control"
                                        readOnly={true}
                                        disabled={true}
                                        value={currentDomain ? currentDomain.domain : ''}
                                        placeholder={i18n.t("Ví dụ: s.tenmiencuaban.com")} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ladiui form-group-custom">
                        <label className="ladiui title-text-form-lever2">{i18n.t('DOMAINS.TRACKING')}</label>
                        <div className="ladiui-row">
                            <div className="ladiui form-group-custom">
                                <div className="ladiui form-group fixed-width">
                                    <input type='text' name='googleTracking'
                                        onChange={e => setGoogleTracking(e.target.value)}
                                        value={googleTracking}
                                        className="ladiui form-control" placeholder={i18n.t('DOMAINS.PLACEHOlDERGG')} />
                                </div>
                            </div>
                            <div className="ladiui form-group-custom fixed-width">
                                <div className="ladiui form-group">
                                    <input type='text' name='facebookTracking'
                                        value={facebookTracking}
                                        onChange={e => setFacebookTracking(e.target.value)}
                                        className="ladiui form-control" placeholder={i18n.t('DOMAINS.PLACEHOlDERFB')} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ladiui modal-footer custom-ldp-decentralization">
                        <div></div>
                        <div className="ladiui footer-modal-btn">
                            <button className="ladiui btn small txt body3 btn-secondary" data-dismiss="modal" data-target="modalUpdateDomain">
                                {i18n.t("Huỷ bỏ")}
                            </button>
                            <button className="ladiui btn small txt body3 btn-primary" onClick={() => onUpdate()}>
                                {i18n.t("Cập nhật")}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default ModalUpdate