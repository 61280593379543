import React from 'react'
import i18n from '../../i18n'
import moment from 'moment';

const ModalViewPackage = ({ userInfo }) => {

    return (
        <>
            <div className="ladiui modal" id="ModalViewPackage">
                <div className="ladiui modal-dialog modal-dialog-centered custom-package-type">
                    <div className="ladiui modal-content custom-ldp">
                        <div className="ladiui modal-header custom-ldp">
                            <h1 className="ladiui modal-title bold">{i18n.t("Thông tin gói sử dụng")}</h1>
                            <button type="button" data-dismiss="modal" data-target="ModalViewPackage" className="ladiui modal-close">
                                <i className="ladiui icon icon-ldp-close"></i>
                            </button>
                        </div>
                        <div className="ladiui container-package-type">
                            <div className="ladiui item-package">
                                <div className="ladiui text-package-type">{i18n.t("Tên gói")}</div>
                                <div className="ladiui value-package-type custom">{userInfo && userInfo.store.pkg_name ? userInfo.store.pkg_name : ""}</div>
                            </div>
                            <div className="ladiui item-package">
                                <div className="ladiui text-package-type">{i18n.t("Ngày kích hoạt")}</div>
                                <div className="ladiui value-package-type">{moment(userInfo ? userInfo.store.created_at : '').format("L")}</div>
                            </div>
                            <div className="ladiui item-package">
                                <div className="ladiui text-package-type">{i18n.t("Ngày hết hạn")}</div>
                                <div className="ladiui value-package-type">{userInfo && (userInfo.store.pkg_exp !== null) ? moment(userInfo ? userInfo.store.pkg_exp : '').format("L") : i18n.t("Không giới hạn")}</div>
                            </div>
                            <div className="ladiui item-package bottom">
                                <div className="ladiui text-package-type">{i18n.t("Số lượng Link")}</div>
                                <div className="ladiui value-package-type">{userInfo && userInfo.store.pkg_name === "FREE" ? "500 Link / Tháng" : i18n.t("Không giới hạn")}</div>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        </>
    )
}

export default ModalViewPackage;





