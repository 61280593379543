import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = { stores: [], userInfo: { store: {} } }, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }

            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            }
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            }
        }
        case types.LIST_STORE: {
            return {
                ...state,
                action: types.LIST_STORE,
                stores: action.status ? action.payload.stores : [],
                totalRecord: action.status ? action.payload.total_record : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_STORES: {
            return {
                ...state,
                action: types.DELETE_STORES,
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_STORE: {
            return {
                ...state,
                action: types.DELETE_STORE,
                status: action.status,
                message: action.message,
            }
        }
        case types.SHOW_STORE: {
            return {
                ...state,
                action: types.SHOW_STORE,
                status: action.status,
                message: action.message,
                store: action.status ? action.payload.store : {},
            }
        }
        case types.CREATE_STORE: {
            return {
                ...state,
                action: types.CREATE_STORE,
                status: action.status,
                message: action.message,
            }
        }
        case types.UPDATE_STORE: {
            return {
                ...state,
                action: types.UPDATE_STORE,
                status: action.status,
                message: action.message,
            }
        }
        case types.GET_USER_INFO: {
            return {
                ...state,
                action: types.GET_USER_INFO,
                status: action.status,
                message: action.message,
                hasStore: action.status ? true : false,
                userInfo: action.status ? { ...action.payload } : {},
            }
        }
        case types.APPLY_DEFAULT_ADDRESS_STORE: {
            return {
                ...state,
                action: types.APPLY_DEFAULT_ADDRESS_STORE,
                status: action.status,
                message: action.message,
            }
        }
        case types.CREATE_NEW_IP: {
            return {
                ...state,
                action: types.CREATE_NEW_IP,
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_IP: {
            return {
                ...state,
                action: types.DELETE_IP,
                status: action.status,
                message: action.message,
            }
        }
        default:
            return state;
    }
}

