import LinkV3 from './pages/linkV3/LinkV3';
import TagV3 from './pages/tagV3/TagV3';
import SettingV3 from './pages/settingV3/SettingV3';
import AddApi from './pages/settingV3/AddApi';
import InfoPackage from './pages/settingV3/InfoPackage';
import Domain from './pages/domains/Domain';
import { map } from 'lodash';
import i18n from './i18n';

const allRoutes = {
    '/':
        [
            { key: "links", path: '/', exact: true, name: `${i18n.t('Links')}`, component: LinkV3 },
        ],
    '/links':
        [
            { key: "links", path: '/links', exact: true, name: `${i18n.t('Links')}`, component: LinkV3 },
        ],
    '/tags':
        [
            { key: "tags", path: '/tags', exact: true, name: `${i18n.t('Tags')}`, component: TagV3, },
        ],
    '/settings':
        [
            { key: "settings", path: '/settings', exact: true, name: `${i18n.t('Cài đặt')}`, component: SettingV3 },
            { key: "settings/domain", path: '/settings/domain', exact: true, name: `${i18n.t('Tên miền')}`, component: Domain },
            { key: "settings/addApi", path: '/settings/addApi', exact: true, name: `${i18n.t('Tích hợp API')}`, component: AddApi },
            { key: "settings/infoPackage", path: '/settings/infoPackage', exact: true, name: `${i18n.t('Thông tin gói sử dụng')}`, component: InfoPackage }
        ]
}

const menuItems = {

    '/links': {
        keyStr: ['links', ''],
        key: 'links',
        classActive: 'ladiui menu-item',
        classLi: "ladiui flex-row-menu-item",
        to: "/links",
        classNav: "ladiui menu-text",
        classIcon: "ladiui icon icon-menu-item1",
        classIconSub: "",
        classToggle: "",
        title: 'Links',
    },
    '/tags': {
        keyStr: ['tags'],
        key: 'tags',
        classActive: 'ladiui menu-item',
        classLi: "ladiui flex-row-menu-item",
        to: "/tags",
        classNav: "ladiui menu-text",
        classIcon: "ladiui icon icon-menu-item2",
        classIconSub: "",
        classToggle: "",
        title: 'Tags',
    },
    '/settings': {
        keyStr: ['settings'],
        key: 'settings',
        classActive: 'ladiui menu-item',
        classLi: "ladiui flex-row-menu-item",
        to: "/settings",
        classNav: "ladiui menu-text",
        classIcon: "ladiui icon icon-menu-item3",
        classIconSub: "",
        classToggle: "",
        title: 'Cài đặt',
    }

};

class Routes {
    getRoutesByScopes(scopes = {}) {
        const routes = [];

        map(allRoutes, (values) => {
            routes.push(...values);
        });
        return routes;
    }

    getItemByScopes(scopes = {}) {
        return menuItems;
    }
}

export default new Routes();