import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const listAll = (data) => ({
    type: types.LISTALL_DOMAIN,
    meta: {
        endpoint: endpoint.DOMAIN_LIST_VERIFY,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const list = (data) => ({
    type: types.LIST_DOMAIN,
    meta: {
        endpoint: endpoint.DOMAIN_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const show = (domainID) => ({
    type: types.SHOW_DOMAIN,
    meta: {
        endpoint: endpoint.DOMAIN_SHOW,
        method: REQUEST_METHOD.POST,
        body: {
            id: domainID,
        },
        hasAuth: true,
    }
});


const deleteDomain = (domainID) => ({
    type: types.DELETE_DOMAINS,
    meta: {
        endpoint: endpoint.DOMAIN_DELETE_ID,
        method: REQUEST_METHOD.POST,
        body: {
            id: domainID
        },
        hasAuth: true,
        showNotify: true
    }
});

const verify = (data) => ({
    type: types.VERIFY_DOMAIN,
    meta: {
        endpoint: endpoint.DOMAIN_VERIFY,
        method: REQUEST_METHOD.POST,
        body: {
            data
        },
        hasAuth: true,
        showNotify: true
    }
});

const create = (data,  cb) => ({
    type: types.CREATE_DOMAIN,
    meta: {
        endpoint: endpoint.DOMAIN_CREATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
        showNotify: true
    }
});

const update = (data, cb) => ({
    type: types.UPDATE_DOMAIN,
    meta: {
        endpoint: endpoint.DOMAIN_UPDATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
        showNotify: true
    }
});

const onSSL = (data, cb) => ({
    type: types.ON_SSL,
    meta: {
        endpoint: endpoint.ON_SSL,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        cb,
        showNotify: true
    }
});

const replace = (data) => ({
    type: types.REPLACE_DOMAIN,
    meta: {
        endpoint: endpoint.DOMAIN_REPLACE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const search = (name) => ({
    type: types.SEARCH_DOMAIN,
    meta: {
        endpoint: endpoint.DOMAIN_SEARCH,
        method: REQUEST_METHOD.POST,
        body: {
            search: name,
        },
        hasAuth: true,
    }
});

const reload = (domainID) => ({
    type: types.RELOAD_DOMAIN,
    meta: {
        endpoint: endpoint.DOMAIN_SHOW,
        method: REQUEST_METHOD.POST,
        body: {
            id: domainID,
        },
        hasAuth: true,
    }
});

const exportDomain = (data) => ({
    type: types.EXPORT_DOMAIN,
    meta: {
        endpoint: endpoint.EXPORT_DOMAIN,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const importDomain = (form) => ({
    type: types.IMPORT_DOMAIN,
    meta: {
        endpoint: endpoint.IMPORT_DOMAIN,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    }
});

export default {
    list,
    listAll,
    show,
    delete: deleteDomain,
    create,
    update,
    search,
    reload,
    replace,
    exportDomain,
    importDomain,
    verify,
    onSSL
}