export const LIST_DOMAIN = 'LIST_DOMAIN';
export const LISTALL_DOMAIN = 'LISTALL_DOMAIN';
export const SHOW_DOMAIN = 'SHOW_DOMAIN';
export const DELETE_DOMAINS = 'DELETE_DOMAINS';
export const DELETE_DOMAIN = 'DELETE_DOMAIN';
export const CREATE_DOMAIN = 'CREATE_DOMAIN';
export const UPDATE_DOMAIN = 'UPDATE_DOMAIN';
export const SEARCH_DOMAIN = 'SEARCH_DOMAIN';
export const RELOAD_DOMAIN = 'RELOAD_DOMAIN';
export const EXPORT_DOMAIN = 'EXPORT_DOMAIN';
export const IMPORT_DOMAIN = 'IMPORT_DOMAIN';
export const REPLACE_DOMAIN = 'REPLACE_DOMAIN';
export const VERIFY_DOMAIN = 'VERIFY_DOMAIN';
export const ON_SSL = 'ON_SSL';