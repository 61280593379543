import React from 'react';
import PropTypes from 'prop-types';
import {
    cloneDeep, remove, find, uniq, map, forEach,
} from 'lodash';
import { withTranslation } from 'react-i18next';
import AsyncAutoComplete from './AsyncAutoComplete';
import ModalAllTag from './ModalAllTag';
import i18n from '../i18n'
import appConfig from '../config/app';

class Tag extends React.Component {
    static propTypes = {
        id: PropTypes.string,
        wrapperStyle: PropTypes.object,
        wrapperProps: PropTypes.object,
        allTags: PropTypes.array,
        onFocus: PropTypes.func,
        loading: PropTypes.bool,
        tabIndex: PropTypes.number,
        selectedTags: PropTypes.array,
        onListAllTags: PropTypes.func,
        toggleResetTag: PropTypes.func,
        hideTitle: PropTypes.bool,
        className: PropTypes.string,
        disabled: PropTypes.bool,
    }

    static defaultProps = {
        hideTitle: false,
        disabled: false,
    }

    constructor(props) {
        super(props);

        this.state = {
            search: '',
            allTags: cloneDeep(map(props.allTags, (item) => item.name)) || [],
            selectedTags: cloneDeep(map(props.selectedTags, (item) => item.name)) || [],
            isShowModalAllTag: false,
        };

        this.loadedAllTag = false;
        this.loadedRecentTag = false;
        this.ref = React.createRef();
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.allTags !== nextProps.allTags) {
            this.setState({
                allTags: cloneDeep(map(nextProps.allTags, (item) => item.name)) || [],
            }, () => this.normalizeRecentTag());
        }

        if (this.props.selectedTags !== nextProps.selectedTags) {
            this.setState({
                selectedTags: cloneDeep(map(nextProps.selectedTags, (item) => item.name)) || [],
            }, () => this.normalizeRecentTag());
        }
    }

    normalizeRecentTag = () => {
        const allTags = cloneDeep(map(this.props.allTags, (item) => item.name)) || [];

        remove(allTags, (tag) => find(this.state.selectedTags, (_tag) => _tag === tag));

        this.setState({
            allTags,
        });
    }

    onChangeInput = (event) => {
        this.setState({
            search: event.target.value,
        });
    }

    onFocus = () => {
    }

    onSelect = (item) => {
        item = item.trim();
        this.setState({
            search: '',
        });

        if (!item) {
            return;
        }
        const { selectedTags } = this.state;
        if (find(selectedTags, (tag) => tag === item)) {
            return;
        }

        selectedTags.push(item);
        this.setState({
            selectedTags,
        }, () => this.normalizeRecentTag());
    }

    removeTag = (item) => {
        const { selectedTags } = this.state;
        if (find(selectedTags, (tag) => tag === item)) {
            remove(selectedTags, (tag) => tag === item);
            // this.props.dataUpdate(selectedTags)
            this.setState({
                selectedTags,
            }, () => this.normalizeRecentTag());
        }
    }

    addNewTag = (newTag) => {
        this.onSelect(newTag);
    }

    getData = () => this.state.selectedTags

    getIDs = () => {
        let result = [];

        forEach(this.state.selectedTags, (item) => {
            const matched = find(this.props.allTags, (_item) => _item.name === item);

            if (matched) {
                result.push(matched._id);
            }
        });

        result = uniq(result);

        return result;
    }

    openModalAllTag = () => {
        if (!this.loadedAllTag) {
            this.props.onListAllTags();
            this.loadedAllTag = true;

            if (this.props.toggleResetTag) {
                this.props.toggleResetTag();
            }
        }

        this.setState({
            isShowModalAllTag: true,
        });
    }

    hideModalAllTag = () => {
        this.setState({
            isShowModalAllTag: false,
        });
    }

    applyTags = (tags) => {
        let { selectedTags } = this.state;

        selectedTags.push(...tags);
        selectedTags = uniq(selectedTags);

        this.setState({
            selectedTags,
        }, () => this.normalizeRecentTag());

        this.hideModalAllTag();
    }


    toNonAccentVietnamese(str) {
        str = str.replace(/A|Á|À|Ã|Ạ|Â|Ấ|Ầ|Ẫ|Ậ|Ă|Ắ|Ằ|Ẵ|Ặ/g, "A");
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/E|É|È|Ẽ|Ẹ|Ê|Ế|Ề|Ễ|Ệ/, "E");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/I|Í|Ì|Ĩ|Ị/g, "I");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/O|Ó|Ò|Õ|Ọ|Ô|Ố|Ồ|Ỗ|Ộ|Ơ|Ớ|Ờ|Ỡ|Ợ/g, "O");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/U|Ú|Ù|Ũ|Ụ|Ư|Ứ|Ừ|Ữ|Ự/g, "U");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/Y|Ý|Ỳ|Ỹ|Ỵ/g, "Y");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/Đ/g, "D");
        str = str.replace(/đ/g, "d");
        str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "");
        str = str.replace(/\u02C6|\u0306|\u031B/g, "");
        return str;
    }

    render() {
        const { t, className, disabled, placeholder } = this.props;
        return (
            <div className={`ladiui clearfix form-tags ${className || ''}`}>
                <div id={this.props.id} className="ladiui form-control custom">
                    <div className="ladiui-tag-list">
                        {
                            map(this.state.selectedTags, (item, index) => <div style={{ zIndex: 1 }} key={index} className="tag">
                                {item}
                                {
                                    !disabled ?
                                        <img src={appConfig.URL_IMAGE.ICON_CLOSE_TAG} alt=""  className="close" onClick={() => {
                                            setTimeout(() => {
                                                this.removeTag(item);
                                            }, 0);
                                        }} />
                                        : null
                                }
                            </div>)
                        }
                    </div>

                    {
                        !disabled ?
                            <AsyncAutoComplete
                                items={this.state.allTags}
                                getItemValue={(item) => item}
                                renderItem={(item, index) => <div key={index}>
                                    {
                                        item.isDefault
                                            ? <></>
                                            : <div className="item" style={{ cursor: 'pointer' }}>
                                                <span className="tag-item">{item}</span>
                                            </div>
                                    }
                                </div>
                                }
                                value={this.state.search}
                                onChange={this.onChangeInput}
                                onSelect={(value, item) => {
                                    setTimeout(() => {
                                        this.onSelect(item);
                                    }, 0);
                                }}

                                shouldItemRender={(item) => this.toNonAccentVietnamese(item).toLowerCase().indexOf(this.toNonAccentVietnamese(this.state.search).toLowerCase().trim()) > -1}
                                inputProps={{
                                    onFocus: this.onFocus,
                                    onKeyPress: (event) => {
                                        if (event.key === 'Enter') {
                                            this.addNewTag(event.target.value);
                                        }
                                    },
                                    className: 'ladiui input custom-input-autocomplete',
                                    placeholder: placeholder ? placeholder : i18n.t("Nhập Tag"),
                                    style: { border: '0px' },
                                    tabIndex: this.props.tabIndex,
                                }}
                                hasDefaultItem={true}
                                isItemSelectable={(item) => {
                                    if (item.isDefault) {
                                        return false;
                                    }
                                    return true;
                                }}
                                loading={this.props.loading}
                                showWhenEmpty={() => <span data-tooltip={t('PRODUCTS.DONT_CHECK_INVENTORY')} data-tooltip-position="right">N/A</span>
                                }
                            /> : null
                    }

                </div>

                {
                    this.state.isShowModalAllTag
                    && <ModalAllTag
                        visible={this.state.isShowModalAllTag}
                        allTags={this.props.allTags}
                        onCancel={this.hideModalAllTag}
                        onSubmit={this.applyTags}
                    />
                }

            </div>
        );
    }
}

export default withTranslation('translation', { withRef: true })(Tag);
