import React, {useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import i18n from '../../../i18n';
import domainActions from '../../../redux/futures/domain/actions';

const ModalCreateDomain = ({ callback }) => {
    const dispatch = useDispatch();
    const [domain, setDomain] = useState();
    const [googleTracking, setGoogleTracking] = useState();
    const [facebookTracking, setFacebookTracking] = useState();
    const [dataDomain, setDataDomain] = useState();

    const onCreate = () => {
        dispatch(domainActions.create(dataDomain, callback));
        window.LadiUI.closeModal('modalCreateDomain');
        setDataDomain({
            domain: "",
            tracking: null,
            redirect301: false
        });
        setDomain("");
        setGoogleTracking("");
        setFacebookTracking("");
    }

    useEffect(() => {
        setDataDomain({
            domain: domain,
            tracking: JSON.stringify({
                "facebook": facebookTracking,
                "google": googleTracking,
            }),
            redirect301: false
        })
    }, [domain, googleTracking, facebookTracking])

    return (
        <div className="ladiui modal" id="modalCreateDomain" >
            <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
                <div className="ladiui modal-content custom-ldp">
                    <div className="ladiui modal-header custom-ldp">
                        <div className="ladiui title-text-form-lever1">{i18n.t('Tạo tên miền')}</div>
                        <button type="button" className="ladiui modal-close" data-dismiss="modal" data-target="modalCreateDomain">
                            <i className="ladiui icon icon-ldp-close"></i>
                        </button>
                    </div>
                    <div className='ladiui flex-row custom-flex-switch'>
                        <div className="ladiui form-group">
                            <div className='ladiui '>
                                <label className="ladiui title-text-form-lever2">{i18n.t("Tên miền")}</label>
                                <div className="ladiui btn-group">
                                    <input
                                        id="pathInput2"
                                        autoComplete="off"
                                        name="domain"
                                        type="text"
                                        className="ladiui form-control"
                                        placeholder={i18n.t("Ví dụ: s.tenmiencuaban.com")}
                                        onChange={e => setDomain(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ladiui form-group-custom">
                        <label className="ladiui title-text-form-lever2">{i18n.t('DOMAINS.TRACKING')}</label>
                        <div className="ladiui-row">
                            <div className="ladiui form-group-custom">
                                <div className="ladiui form-group fixed-width">
                                    <input type='text' name='googleTracking'
                                        onChange={e => setGoogleTracking(e.target.value)}
                                        className="ladiui form-control" placeholder={i18n.t('DOMAINS.PLACEHOlDERGG')} />
                                </div>
                            </div>
                            <div className="ladiui form-group-custom fixed-width">
                                <div className="ladiui form-group">
                                    <input type='text' name='facebookTracking'
                                        onChange={e => setFacebookTracking(e.target.value)}
                                        className="ladiui form-control" placeholder={i18n.t('DOMAINS.PLACEHOlDERFB')} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ladiui modal-footer custom-ldp-decentralization">
                        <div></div>
                        <div className="ladiui footer-modal-btn">
                            <button className="ladiui btn small txt body3 btn-secondary" data-dismiss="modal" data-target="modalCreateDomain">
                                {i18n.t("Huỷ bỏ")}
                            </button>
                            <button className="ladiui btn small txt body3 btn-primary" onClick={() => onCreate()}>
                                {i18n.t("Tạo tên miền")}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default ModalCreateDomain