import React, { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../../../i18n';
import Tag from '../../../components/Tag'
import linkActions from '../../../redux/futures/link/actions';
import domainActions from '../../../redux/futures/domain/actions';
import appConfig from '../../../config/app';
import parseUrl from 'is-valid-http-url';
import queryString from 'query-string';

const ModalUpdateLink = ({ tags, callback, itemLink, tagSelected, setTagSelected }) => {
     const dispatch = useDispatch();
     const tagRef = useRef();

     const [originLink, setOriginLink] = useState({ value: "" });

     const [campaignSource, setCampaignSource] = useState();
     const [campaignMedium, setCampaignMedium] = useState();
     const [campaignName, setCampaignName] = useState();
     const [campaignTerm, setCampaignTerm] = useState();
     const [campaignContent, setCampaignContent] = useState();
     const domains = useSelector(state => state.domain.domains);
     const [dataUpdate, setDataUpdate] = useState({
          description: "",
          domain: "ldp.to",
          image: "",
          ladi_path: "",
          origin_link: "",
          tags: [],
          title_link: "",
          _id: "",
     })

     const handleChange = (value) => {
          if (value && value != "") {
               if (parseUrl(value)) {
                    const parse = queryString.parseUrl(value);
                    setCampaignSource(parse.query.utm_source)
                    setCampaignMedium(parse.query.utm_medium)
                    setCampaignName(parse.query.utm_campaign)
                    setCampaignTerm(parse.query.utm_term)
                    setCampaignContent(parse.query.utm_content)
               } else {
                    setCampaignSource('')
                    setCampaignMedium('')
                    setCampaignName('')
                    setCampaignTerm('')
                    setCampaignContent('')
               }
          }
     }

     const onChangeUTM = (e, type) => {
          const order = appConfig.UTM;
          const parse = queryString.parseUrl(originLink.value);

          switch (type) {
               case "SOURCE":
                    if (campaignSource != String(e.target.value).trim()) {
                         setCampaignSource(String(e.target.value).trim());
                         if (parseUrl(originLink.value)) {
                              if (e.target.value) {
                                   parse.query.utm_source = e.target.value;
                              } else {
                                   delete parse.query.utm_source;
                              }
                              const newOriginLink = queryString.stringifyUrl(parse, { sort: (itemLeft, itemRight) => order.indexOf(itemLeft) - order.indexOf(itemRight) });
                              setOriginLink({ value: newOriginLink });
                         }
                    }
                    break;
               case "MEDIUM":
                    if (campaignMedium != String(e.target.value).trim()) {
                         setCampaignMedium(String(e.target.value).trim())
                         if (parseUrl(originLink.value)) {
                              if (e.target.value) {
                                   parse.query.utm_medium = e.target.value;
                              } else {
                                   delete parse.query.utm_medium;
                              }
                              const newOriginLink = queryString.stringifyUrl(parse, { sort: (itemLeft, itemRight) => order.indexOf(itemLeft) - order.indexOf(itemRight) });
                              setOriginLink({ value: newOriginLink })
                         }
                    }
                    break;
               case "NAME":
                    if (campaignName != String(e.target.value).trim()) {
                         setCampaignName(String(e.target.value).trim());
                         if (parseUrl(originLink.value)) {
                              if (e.target.value) {
                                   parse.query.utm_campaign = e.target.value;
                              } else {
                                   delete parse.query.utm_campaign;
                              }
                              const newOriginLink = queryString.stringifyUrl(parse, { sort: (itemLeft, itemRight) => order.indexOf(itemLeft) - order.indexOf(itemRight) });
                              setOriginLink({ value: newOriginLink })
                         }
                    }
                    break;
               case "TERM":
                    if (campaignTerm != String(e.target.value).trim()) {
                         setCampaignTerm(String(e.target.value).trim());
                         if (parseUrl(originLink.value)) {
                              if (e.target.value) {
                                   parse.query.utm_term = e.target.value;
                              } else {
                                   delete parse.query.utm_term;
                              }
                              const newOriginLink = queryString.stringifyUrl(parse, { sort: (itemLeft, itemRight) => order.indexOf(itemLeft) - order.indexOf(itemRight) });
                              setOriginLink({ value: newOriginLink })
                         }
                    }
                    break;
               case "CONTENT":
                    if (campaignContent != String(e.target.value).trim()) {
                         setCampaignContent(String(e.target.value).trim());
                         if (parseUrl(originLink.value)) {
                              if (e.target.value) {
                                   parse.query.utm_content = e.target.value;
                              } else {
                                   delete parse.query.utm_content;
                              }
                              const newOriginLink = queryString.stringifyUrl(parse, { sort: (itemLeft, itemRight) => order.indexOf(itemLeft) - order.indexOf(itemRight) });
                              setOriginLink({ value: newOriginLink })
                         }
                    }
                    break;
               default:
                    break;
          }
     }

     const onUpdate = () => {
          if (dataUpdate && dataUpdate.origin_link === '') {
               setTimeout(() => { window.LadiUI.showInputMessage(document.getElementById("urlInput"), true, true, i18n.t("Vui lòng nhập vào trường này")); }, 1);
               return;
          }
          if (dataUpdate && dataUpdate.origin_link && parseUrl(dataUpdate.origin_link) == false) {
               setTimeout(() => { window.LadiUI.showInputMessage(document.getElementById("urlInput"), true, true, i18n.t("Link không hợp lệ")); }, 1);
               return;
          }

          if (dataUpdate && dataUpdate.ladi_path == "") {
               setTimeout(() => { window.LadiUI.showInputMessage(document.getElementById("pathInput"), true, true, i18n.t("Vui lòng nhập vào trường này")); }, 1);
               return;
          }

          const expression = /[^A-Za-z0-9-_]/;
          const regex = new RegExp(expression);
          let slash = dataUpdate.ladi_path;

          if (slash.match(regex)) {
               setTimeout(() => { window.LadiUI.showInputMessage(document.getElementById("pathInput"), true, true, i18n.t("Đường dẫn không hợp lệ")); }, 1);
               return;
          } else {
               // no code
          }

          if (tagRef.current) {
               const _tagIds = tagRef.current.getIDs() || [];
               setDataUpdate({ ...dataUpdate, tags: _tagIds });
          }
          dataUpdate.tags = tagRef.current ? tagRef.current.getData() : [];
          dispatch(linkActions.update(dataUpdate, callback));
          window.LadiUI.closeModal('ModalUpdateLink');
          setDataUpdate({
               description: "",
               domain: "ldp.to",
               image: "",
               ladi_path: "",
               origin_link: "",
               tags: [],
               title_link: "",
               _id: "",
          })
          setOriginLink({ value: "" })
     }

     const onResetModal = (e) => {
          let isClose = false;
          if (!e.target.classList) return;
          const classList = e.target.classList;
          const dismiss = e.target.getAttribute("data-dismiss");
          if (classList.contains('modal') && classList.contains('open'))
               isClose = true;
          if (dismiss && dismiss === 'modal')
               isClose = true;
          if (isClose) {
               setCampaignSource("")
               setCampaignMedium("")
               setCampaignName("")
               setCampaignTerm("")
               setCampaignContent("")
          }
     }

     useEffect(() => {
          if (itemLink) {
               if (itemLink && itemLink.origin_https == true) {
                    if (itemLink.origin_path == "") {
                         setOriginLink({ value: `https://${itemLink.origin_domain}` })
                    } else {
                         setOriginLink({ value: `https://${itemLink.origin_domain}/${itemLink.origin_path}` })
                    }
               } if (itemLink && itemLink.origin_https == false) {
                    if (itemLink.origin_path == "") {
                         setOriginLink({ value: `http://${itemLink.origin_domain}` })
                    } else {
                         setOriginLink({ value: `http://${itemLink.origin_domain}/${itemLink.origin_path}` })
                    }
               }

               setDataUpdate({
                    description: itemLink && itemLink.description ? itemLink.description : "",
                    domain: itemLink.domain || 'ldp.to',
                    image: itemLink && itemLink.image ? itemLink.image : "",
                    ladi_path: itemLink && itemLink.path ? itemLink.path : "",
                    origin_link: itemLink && itemLink.origin_https == true ?
                         `https://${itemLink.origin_domain}/${itemLink.origin_path}` :
                         itemLink && itemLink.origin_https == false ?
                              `http://${itemLink.origin_domain}/${itemLink.origin_path}` : "",
                    tags: [],
                    title_link: itemLink && itemLink.title ? itemLink.title : "",
                    _id: itemLink && itemLink._id ? itemLink._id : "",
               })
          }

     }, [itemLink])

     useEffect(() => {
          handleChange(originLink.value);
          dispatch(domainActions.list());
          setDataUpdate({ ...dataUpdate, origin_link: originLink.value })
     }, [originLink])

     return (
          <div className="ladiui modal" id="ModalUpdateLink" onClick={onResetModal}>
               <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
                    <div className="ladiui modal-content custom-ldp">
                         <div className="ladiui modal-header custom-ldp">
                              <div className="ladiui title-text-form-lever1">{i18n.t('Chỉnh sửa Link')}</div>
                              <button type="button" className="ladiui modal-close" data-dismiss="modal" data-target="ModalUpdateLink">
                                   <i className="ladiui icon icon-ldp-close"></i>
                              </button>
                         </div>
                         <div className="ladiui form-group-custom">
                              <label className="ladiui title-text-form-lever2">{i18n.t("Link gốc")}</label>
                              <input
                                   id="urlInput"
                                   autoComplete="off"
                                   name="name"
                                   type="text"
                                   className="ladiui form-control custom-ladilink"
                                   placeholder={i18n.t("Nhập Link gốc của bạn")}
                                   value={originLink.value}
                                   onInput={e => setOriginLink({ value: e.target.value })}
                              />
                              <div className="ladiui select-search dropdown" id="dropdown-staff">
                                   <div className="ladiui dropdown-toggle"
                                        id="dropdownMenuButton4" data-toggle="dropdown"
                                        style={{ fontSize: '12px', weight: '400', lineHeight: '16px', color: '#1C00C2', position: 'absolute', top: '-25px', right: '-490px', cursor: 'pointer', }}>
                                        Tạo UTM
                                   </div>
                                   <ul className="ladiui dropdown-menu custom-people field">
                                        <li className="ladiui search-group custom-people">
                                             <div className="ladiui" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gridGap: '16px', marginTop: '16px' }}>
                                                  <div style={{ width: '100%' }}>
                                                       <div className='ladiui title-sub-ladilink'>Campaign Source</div>
                                                       <input id="campaignSource" className="ladiui setting form-control" placeholder="facebook, google, tiktok" onChange={e => onChangeUTM(e, "SOURCE")} value={campaignSource}></input>
                                                  </div>
                                                  <div style={{ width: '100%' }}>
                                                       <div className='ladiui title-sub-ladilink'>Campaign Medium</div>
                                                       <input id="campaignMedium" className="ladiui setting form-control" placeholder="search, display, remarketing" onChange={e => onChangeUTM(e, "MEDIUM")} value={campaignMedium}></input>
                                                  </div>
                                             </div>
                                             <div className="ladiui" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gridGap: '16px', marginTop: '28px' }}>
                                                  <div style={{ width: '100%' }}>
                                                       <div className='ladiui title-sub-ladilink'>Campaign Name</div>
                                                       <input id="campaignName" className="ladiui setting form-control" placeholder="Campaign Name" onChange={e => onChangeUTM(e, "NAME")} value={campaignName}></input>
                                                  </div>
                                                  <div style={{ width: '100%' }}>
                                                       <div className='ladiui title-sub-ladilink'>URL Campaign Term</div>
                                                       <input id="campaignTerm" className="ladiui setting form-control" placeholder="Campaign Term" onChange={e => onChangeUTM(e, "TERM")} value={campaignTerm}></input>
                                                  </div>
                                             </div>
                                             <div className="ladiui" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gridGap: '16px', marginTop: '28px' }}>
                                                  <div style={{ width: '100%' }}>
                                                       <div className='ladiui title-sub-ladilink'>Campaign Content</div>
                                                       <input id="campaignContent" className="ladiui setting form-control" placeholder="Campaign Content" onChange={e => onChangeUTM(e, "CONTENT")} vale={campaignContent}></input>
                                                  </div>
                                                  <div style={{ width: '100%' }}></div>
                                             </div>
                                        </li>
                                   </ul>
                              </div>
                         </div>

                         <div>
                              <div className='ladiui flex-row custom-flex-switch'>
                                   <div className="ladiui form-group-custom">
                                        <div className='ladiui'>
                                             <label className="ladiui title-text-form-lever2">{i18n.t("Tên miền")}</label>
                                             <div className="ladiui btn-group fixed-width">
                                                  <div className="ladiui dropdown">
                                                       <button id='package' className="ladiui btn btn-outline-light dropdown-toggle  select-dropdown fixed-width" data-toggle="dropdown">
                                                            <i></i>
                                                            <span className="ladiui dropdown-text">{dataUpdate.domain}</span>
                                                       </button>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>

                                   <div className="ladiui form-group-custom">
                                        <div className='ladiui '>
                                             <label className="ladiui title-text-form-lever2">{i18n.t("Đường dẫn")}</label>
                                             <div className="ladiui btn-group fixed-width">
                                                  <input
                                                       id="pathInput"
                                                       autoComplete="off"
                                                       name="name"
                                                       type="text"
                                                       className="ladiui form-control"
                                                       placeholder={i18n.t("Nhập đường dẫn")}
                                                       value={dataUpdate ? dataUpdate.ladi_path : ""}
                                                       onChange={e => setDataUpdate({ ...dataUpdate, ladi_path: e.target.value })}
                                                  />
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              <div className="ladiui form-group-custom">
                                   <label className="ladiui title-text-form-lever2">{i18n.t("Tag")}</label>
                                   <Tag allTags={tags} ref={tagRef} selectedTags={tagSelected} />
                              </div>

                              <div className="ladiui modal-footer custom-ldp-decentralization">
                                   <div></div>
                                   <div className="ladiui footer-modal-btn">
                                        <button className="ladiui btn small txt body3 btn-secondary" data-dismiss="modal" data-target="ModalUpdateLink">
                                             {i18n.t("Huỷ bỏ")}
                                        </button>
                                        <button className="ladiui btn small txt body3 btn-primary" onClick={() => onUpdate()}>
                                             {i18n.t("Cập nhật")}
                                        </button>
                                   </div>
                              </div>
                         </div>

                    </div>
               </div>
          </div >
     )
}

export default ModalUpdateLink