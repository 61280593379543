import React from 'react';
import SideBar from './Sidebar';
import Topbar from './Topbar';
import Footer from './Footer';
import { map } from 'lodash';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../../routes';
import baseHelper from '../../helpers/BaseHelper';
import appConfig from '../../config/app';
import i18n from '../../i18n'


class AppLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pageName: ''
        };
    }

    componentWillReceiveProps(newProps) {
        const availableRoutes = routes.getRoutesByScopes({});
        let _name = '';
        for (let i = 0; i < availableRoutes.length; i++) {
            if (window.location.pathname.indexOf(availableRoutes[i].key) > 0) {
                _name = availableRoutes[i].name;
            }
        }
        this.setState({
            pageName: _name
        })
    }

    render() {

        var lang = baseHelper.getCookie(appConfig.COOKIE.LANGUAGE);
        if (!lang) {
            lang = 'vi';
        }
        document.documentElement.setAttribute('lang', lang);

        const { t } = this.props;
        const { scopes, userInfo } = this.props.store;
        const availableRoutes = routes.getRoutesByScopes(scopes);
        let _cls = 'lang-' + lang;

        return (
            <>
                <div className="ladi-popupx-inline" data-ladipage-id="6635b35e463fa60012607eae" data-align="center"
                     data-scrolling="no"></div>
                <section className={`ladiui wrapper ` + _cls}>
                    <SideBar scopes={scopes} />

                    <div className="ladiui-main">
                        <div id="topbar-top" className="ladiui topbar flex-row">
                            <div className="ladiui topbar-menu">{this.state.pageName ? i18n.t(this.state.pageName) : 'Links'}</div>
                            <Topbar />
                        </div>
                        <Switch>
                            {
                                map(availableRoutes, (route, index) =>
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        render={() => <route.component />}
                                    />
                                )
                            }
                            <Route render={() => <Redirect to="/" />} />
                        </Switch>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
    }
}

const mapStateToProps = (state) => ({
    store: { ...state.store }
});


export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);