import React from 'react';
import i18n from '../../../i18n'
import { withTranslation } from 'react-i18next';
import LadiDropdownMenu from '../../../components/LadiDropdownMenu';

function FormMultiAction(props) {
    const ids = props.ids || [];

    const listAction = [
        { value: 'DELETE_ALL', name: i18n.t('Xoá') }
    ];

    const listActionDelete = [
        { value: 'UN_DELETE', name: i18n.t('Khôi phục') }
    ];

    const eventSelectAction = (action) => {
        switch (action) {
            case 'DELETE_ALL':
                window.LadiUI.showModal('modalDelete');
                break;
            default:
        }
    };

    const eventSelectActionDelete = () => {
        window.LadiUI.showModal('modalRestore');
    };

    return ids.length > 0
        ?
        <div className="ladiui ladi-check-all-action">
            <LadiDropdownMenu
                labelDropdown={i18n.t('Chọn hành động')}
                listProp={props.status && props.status === true ? listActionDelete : listAction}
                defaultTextProp={i18n.t('Hành động')}
                hideLabel={true}
                cbProp={props.status && props.status === true ? eventSelectActionDelete : eventSelectAction}
                classSize="btn-sm"
                classDropdown="w-220"
            />
        </div>
        : <></>;
}

export default (withTranslation()(FormMultiAction));
