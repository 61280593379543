const config = {
    API_V1_0: "https://api.dev.ladilink.com/1.0",
    API_UID_V2_0: "https://api.dev.ladiuid.com/2.0",
    ACCOUNT_UID: "https://accounts.dev.ladiuid.com",
}
const authEndpoint = {
    AUTH_SIGNIN: `${config.ACCOUNT_UID}/signin?callback_url=https://app.dev.ladilink.com&app_code=ll`,
    AUTH_SIGNUP: `${config.ACCOUNT_UID}/signup?callback_url=https://app.dev.ladilink.com&app_code=ll`,
    AUTH_SIGNOUT: `${config.ACCOUNT_UID}/signin?logout=1&callback_url=https://app.dev.ladilink.com&app_code=ll`,
}
export default config;
export {
    authEndpoint
}
