import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const getApiInfo = (isNew = false) => ({
    type: types.API_INFO,
    meta: {
        endpoint: endpoint.API_INFO,
        method: REQUEST_METHOD.POST,
        hasAuth: true,
        body: {
            new_key: isNew
        }
    },
});

export default {
    getApiInfo,
};
