import { combineReducers } from 'redux';
import commonReducer from '../futures/common/reducer';
import authReducer from '../futures/auth/reducer';
import customerReducer from '../futures/customer/reducer';
import storeReducer from '../futures/store/reducer';
import domainReducer from '../futures/domain/reducer';
import linkReducer from '../futures/link/reducer';
import tagReducer from '../futures/tag/reducer';
import apiReducer from '../futures/api/reducer';

import { connectRouter } from 'connected-react-router';

export default (history) => combineReducers({
    common: commonReducer,
    auth: authReducer,
    customer: customerReducer,
    store: storeReducer,
    domain: domainReducer,
    link: linkReducer,
    tag: tagReducer,
    api: apiReducer,
    router: connectRouter(history),
});


