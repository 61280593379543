import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = { links: [], newLinks: [] }, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            }
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            }
        }
        case types.LIST_LINK: {
            return {
                ...state,
                action: types.LIST_LINK,
                newLinks: action.status ? action.payload.items : [],
                links: action.status ? action.payload.items : [],
                totalRecord: action.status ? action.payload.total : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.SHOW_LINK: {
            return {
                ...state,
                action: types.SHOW_LINK,
                link: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            }
        }
        case types.SEARCH_LINK: {
            return {
                ...state,
                action: types.SEARCH_LINK,
                links: action.status ? action.payload.links : [],
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_LINKS: {
            return {
                ...state,
                action: types.DELETE_LINKS,
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_LINK: {
            return {
                ...state,
                action: types.DELETE_LINK,
                status: action.status,
                message: action.message,
            }
        }
        case types.CREATE_LINK: {
            return {
                ...state,
                action: types.CREATE_LINK,
                link: action.status ? action.payload.link : {},
                shortLink: action.status ? action.payload.rsShortLink : {},
                status: action.status,
                message: action.message,
            }
        }
        case types.UPDATE_LINK: {
            return {
                ...state,
                action: types.UPDATE_LINK,
                shortLink: action.status ? action.payload.rsShortLink : {},
                status: action.status,
                message: action.message,
            }
        }
        case types.RELOAD_LINK: {
            return {
                ...state,
                action: types.RELOAD_LINK,
                link: action.status ? action.payload.link : {},
                status: action.status,
                message: action.message,
            }
        }
        case types.IMPORT_LINK: {
            return {
                ...state,
                action: types.IMPORT_LINK,
                status: action.status,
                message: action.message,
            }
        }
        case types.EXPORT_LINK: {
            return {
                ...state,
                action: types.EXPORT_LINK,
                status: action.status,
                message: action.message,
            }
        }
        case types.GENERATESLASH: {       
            const type = action.payload ? action.payload.slash : null;
            let data;
            let domain
            if (type) {
                data = action.payload.slash
            } else {
                data = action.payload && action.payload.path ? action.payload.path : '';
                domain = action.payload && action.payload.domain ? action.payload.domain : '';
            }
            return {
                ...state,
                action: types.GENERATESLASH,
                slash: action.status ? data : '',
                domain: action.status ? domain : '',
                dataSlash: action.status ? action.payload : '',
                typeSlash: action.status ? action.payload.slash : '',
                status: action.status,
                message: action.message,
            }
        }
        case types.GENERATEINFO: {
            return {
                ...state,
                action: types.GENERATEINFO,
                data: action.status ? action.payload : '',
                status: action.status,
                message: action.message,
            }
        }
        case types.RESET_LINK: {
            return {
                ...state,
                links: [],
            }
        }
        case types.UPLOAD_FILE: {
            return {
                ...state,
                action: types.UPLOAD_FILE,
                data: action.status ? action.payload : '',
                status: action.status,
                message: action.message,
            }
        }
        default:
            return state;
    }
}

