import React, {useEffect, useState} from 'react'
import SlidebarItem from './SidebarItem'
import i18n from '../../i18n'
import moment from 'moment';
import { useSelector } from 'react-redux'


const InfoPackage = () => {
     const userInfo = useSelector(state => state.store.userInfo);

     const [maxLink, setMaxLink] = useState(null);
     const [maxDomain, setMaxDomain] = useState(null);

     useEffect(() => {
          console.log("33333.............", userInfo);
          console.log("12345.............", JSON.parse(userInfo.store.resource_limits));
          let link = null;
          let domain = null;

          if (userInfo && userInfo.store.resource_limits) {
               let resourceLimit = JSON.parse(userInfo.store.resource_limits);

               setMaxDomain(resourceLimit.MAX_DOMAIN ? resourceLimit.MAX_DOMAIN : null);
               setMaxLink(resourceLimit.MAX_LINK ? resourceLimit.MAX_LINK : null);
          } else {
               setMaxLink(userInfo && userInfo.pkg.max_link ? userInfo.pkg.max_link : null);
               setMaxDomain(userInfo && userInfo.pkg.max_domain ? userInfo.pkg.max_domain : null);
          }
     }, [userInfo]);

     return (
          <div className="ladiui content-list-landingpage">
               <SlidebarItem active={"infoPackage"} />
               <div className="ladiui content-main">
                    <div className="ladiui setting-content-parent">
                         <div className="ladiui shared setting-content-right">
                              <div className="ladiui setting-name body-8 bold">{i18n.t("Thông tin gói sử dụng")}</div>
                         </div>

                         <div className="ldiui package-width">
                              <div className="ladiui content-package">
                                   <div className="ladiui container-package-type">
                                        <div className="ladiui item-package">
                                             <div className="ladiui text-package-type">{i18n.t("Tên gói")}</div>
                                             <div className="ladiui value-package-type custom">{userInfo && userInfo.store.pkg_name ? userInfo.store.pkg_name : ""}</div>
                                        </div>
                                        <div className="ladiui item-package">
                                             <div className="ladiui text-package-type">{i18n.t("Ngày kích hoạt")}</div>
                                             <div className="ladiui value-package-type">{moment(userInfo ? userInfo.store.created_at : '').format("L")}</div>
                                        </div>
                                        <div className="ladiui item-package">
                                             <div className="ladiui text-package-type">{i18n.t("Ngày hết hạn")}</div>
                                             <div className="ladiui value-package-type">{userInfo && (userInfo.store.pkg_exp !== null) ? moment(userInfo ? userInfo.store.pkg_exp : '').format("L") : i18n.t("Không giới hạn")}</div>
                                        </div>
                                        {
                                             userInfo && userInfo.store && userInfo.store.pkg_name.includes('LIMIT')
                                                  ? "" :
                                            <>
                                                  <div className="ladiui item-package bottom">
                                                       <div className="ladiui text-package-type">{i18n.t("Số lượng Link")}</div>
                                                       <div className="ladiui value-package-type">{maxLink ? maxLink + " links" : ""}</div>
                                                  </div>
                                                 <div className="ladiui item-package bottom">
                                                      <div
                                                          className="ladiui text-package-type">{i18n.t("Số lượng tên miền")}</div>
                                                      <div
                                                          className="ladiui value-package-type">{maxDomain ? maxDomain + " domain" : ""}</div>
                                                 </div>
                                            </>
                                        }
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default InfoPackage