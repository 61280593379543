import config from './config';
import i18n from '../i18n';

export const status = {
    OK: 200,
    CREATED: 201,
    NO_STORE: 204,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
    METHOD_NOT_ALLOWED: 405,
    REQUEST_TIMEOUT: 408,
    MANY_REQUEST: 429,
    EXPIRED_SESSION: 503,
    ERROR: 500,
}

export const endpoint = {
    //--------------------LUID------------------------
    PROFILE: `${config.ACCOUNT_UID}/profile`,
    //---------------------------------------------
    ACCOUNT_PROFILE: `${config.ACCOUNT_UID}/account/profile`,

    //--------------------LS-------------------------
    STORE_INFO: `${config.API_V1_0}/store/info`,
    //TAG
    TAG_LIST: `${config.API_V1_0}/tag/list`,
    TAG_SHOW: `${config.API_V1_0}/tag/show`,
    TAG_DELETE_IDS: `${config.API_V1_0}/tag/deletes`,
    TAG_DELETE_ID: `${config.API_V1_0}/tag/delete`,
    TAG_CREATE: `${config.API_V1_0}/tag/create`,
    TAG_UPDATE: `${config.API_V1_0}/tag/update`,
    TAG_SEARCH: `${config.API_V1_0}/tag/search`,
    APPLY_DEFAULT_ADDRESS_TAG: `${config.API_V1_0}/tag/apply-default-address`,
    LIST_DETAIL_TAG_ORDER: `${config.API_V1_0}/tag/list-detail-order`,
    EXPORT_TAG: `${config.API_V1_0}/file/export-tag`,
    IMPORT_TAG: `${config.API_V1_0}/file/import-tag`,

    //LINK
    PATH_LIST: `${config.API_V1_0}/ladi-link/generate-slash`,
    LINK_GENERATE_INFO: `${config.API_V1_0}/ladi-link/generate-info`,
    LINK_LIST: `${config.API_V1_0}/ladi-link/list`,
    LINK_SHOW: `${config.API_V1_0}/ladi-link/show`,
    LINK_DELETE_IDS: `${config.API_V1_0}/ladi-link/deletes`,
    LINK_DELETE_ID: `${config.API_V1_0}/ladi-link/delete`,
    LINK_CREATE: `${config.API_V1_0}/ladi-link/create`,
    LINK_UPDATE: `${config.API_V1_0}/ladi-link/update`,
    LINK_SEARCH: `${config.API_V1_0}/ladi-link/search`,
    APPLY_DEFAULT_ADDRESS_LINK: `${config.API_V1_0}/ladi-link/apply-default-address`,
    LIST_DETAIL_LINK_ORDER: `${config.API_V1_0}/ladi-link/list-detail-order`,
    EXPORT_LINK: `${config.API_V1_0}/file/export-link`,
    IMPORT_LINK: `${config.API_V1_0}/file/import-link`,
    UPLOAD_FILE: `${config.API_V1_0}/ladi-link/upload-file`,

    //DOMAIN
    DOMAIN_LIST_VERIFY: `${config.API_V1_0}/domain/list-verify`,
    DOMAIN_LIST: `${config.API_V1_0}/domain/list`,
    DOMAIN_SHOW: `${config.API_V1_0}/domain/show`,
    DOMAIN_DELETE_ID: `${config.API_V1_0}/domain/delete`,
    DOMAIN_VERIFY: `${config.API_V1_0}/domain/verify`,
    DOMAIN_CREATE: `${config.API_V1_0}/domain/create`,
    DOMAIN_UPDATE: `${config.API_V1_0}/domain/update`,
    ON_SSL: `${config.API_V1_0}/domain/on-ssl`,
    DOMAIN_SEARCH: `${config.API_V1_0}/domain/search`,
    APPLY_DEFAULT_ADDRESS_DOMAIN: `${config.API_V1_0}/domain/apply-default-address`,
    LIST_DETAIL_DOMAIN_ORDER: `${config.API_V1_0}/domain/list-detail-order`,
    EXPORT_DOMAIN: `${config.API_V1_0}/file/export-domain`,
    IMPORT_DOMAIN: `${config.API_V1_0}/file/import-domain`,
    DOMAIN_REPLACE: `${config.API_V1_0}/domain/replace`,

    //API_KEY
    API_INFO: `${config.API_V1_0}/api/info`,

    //STORE
    STORE_UPDATE: `${config.API_V1_0}/store/update`

}

export default {
    COOKIE: {
        SSID: 'SSID',
        STORE_ID: 'STORE_ID',
        EXPIRE: 365,
        LANGUAGE: 'LANGUAGE',
    },
    LIST_COLOR: [
        '#F24A72',
        '#333C83',
        '#53BF9D',
        '#BD4291',
        '#693C72',
        '#111D5E',
        '#799351',
        '#FD8C04',
    ],
    LANGUAGES: [
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-vi.svg',
            code: 'vi',
            name: 'Vietnamese',
        },
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-en.svg',
            code: 'en',
            name: 'English',
        },
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-th.svg',
            code: 'th',
            name: 'Thailand',
        },
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-id.svg',
            code: 'id',
            name: 'Indonesia',
        },
        {
            icon: 'https://w.ladicdn.com/ladiui/icons/icon-flag-my.svg',
            code: 'my',
            name: 'Malaysia',
        }
    ],
    LOCAL_FORAGE: {
        COUNTRY_KEY: 'COUNTRIES',
        STATE_KEY: 'STATES',
        DISTRICT_KEY: 'DISTRICTS',
        WARD_KEY: 'WARDS',
        USER_INFO: 'USER_INFO',
        RECENT_PARTNRER_CODE: 'RECENT_PARTNRER_CODE',
    },
    VER: '2.0',
    DEFAULT_FORMAT_DATE_TIME: 'DD/MM/YYYY HH:mm',
    DEFAULT_FORMAT_DATE: 'DD/MM/YYYY',
    REPORT_OVERTIME_FORMAT_DATE: 'DD/MM',
    DEFAULT_DATE_PICKER_FORMAT_DATE: "dd-MM-yyyy",
    DEFAUT_TIME: "HH:mm",
    DB_DATE_TIME_FORMAT: 'DD-MM-YYYY HH:mm:ss',

    PAGINATION: {
        DEFAULT_LIMIT: 10,
        LIMIT_OPTIONS: [
            { value: 10, name: 10 },
            { value: 20, name: 20 },
            { value: 50, name: 50 },
            { value: 100, name: 100 },
        ]
    },
    URL_IMAGE: {
        TABLE_TAG: "https://w.ladicdn.com/ladiui/ladipage/icon-ldp-table-tag.svg",
        ICON_ADD: "https://w.ladicdn.com/ladiui/ladipage/icon-ldp-add.svg",
        ICON_ADD_FUNNEL: "https://w.ladicdn.com/ladiui/ladipage/icon-add-funnel.svg",
        ICON_SHOW_MORE: 'https://w.ladicdn.com/ladiui/ladipage/icon-show-more.svg',
        ICON_SHOW_TAB: 'https://w.ladicdn.com/ladiui/ladipage/icon-show-tab.svg',
        ICON_CLOSE_TAG: "https://w.ladicdn.com/ladiui/ladipage/icon-close-tag.svg",
        ICON_HELP_TOPBAR: "https://w.ladicdn.com/ladiui/ladipage/icon-help-topbar.svg",
        ICON_NOT_FODER: "https://w.ladicdn.com/ladiui/ladipage/icon-not-foder.svg",
        BANNER_LANDINGPAGE: "https://w.ladicdn.com/ladiui/ladipage/banner-landingpage.svg",
        BANNER_ADS: "https://w.ladicdn.com/ladiui/ladipage/banner-banner-ads.svg",
        BANNER_POPUP: "https://w.ladicdn.com/ladiui/ladipage/banner-popupx.svg",
        BACKGROUD_DYNAMIC: "https://w.ladicdn.com/ladiui/ladipage/banner-dynamic.svg",
        BACKGROUD_FUNNEL: "https://w.ladicdn.com/ladiui/ladipage/banner-funnel.svg",
        BACKGROUD_ACCESS: "https://w.ladicdn.com/ladiui/ladipage/banner-access.svg?v=1",
        NOTIFICATION_ICON: "https://w.ladicdn.com/ladiui/ladipage/icon-ldp-notification.svg",
        BACKGROUD_TEAM: "https://w.ladicdn.com/ladiui/ladipage/banner-team.svg",
        BACKGROUD_DOMAIN: "https://w.ladicdn.com/ladiui/ladipage/banner-domain.svg",
        BACKGROUD_FORM_ACCOUNT: 'https://w.ladicdn.com/ladiui/ladipage/banner-form-acount.svg',
        BACKGROUD_FORM_CONFIG: 'https://w.ladicdn.com/ladiui/ladipage/banner-form-config.svg',
        BACKGROUD_TRACKING_GLOBAL: 'https://w.ladicdn.com/ladiui/ladipage/banner-teacking.svg',
    },
    STATUS_OPTIONS: [
        { value: 1, name: `${i18n.t('STATUS.ACTIVE')}` },
        { value: 0, name: `${i18n.t('STATUS.INACTIVE')}` },
    ],
    FORM_MODE: {
        CREATE: 'CREATE',
        EDIT: 'EDIT',
        VIEW: 'VIEW',
        REPLACE: 'REPLACE',
        EDIT_IMAGE: 'EDIT_IMAGE',
        SHARE: 'SHARE'
    },
    APP: {
        LS: 'ls',
        LP: 'lp',
    },
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 0,
    },
    MODE: {
        TEST: 0,
        PRODUCTION: 1
    },
    WHITE_LOGO: "https://w.ladicdn.com/source/v3/logo_ladilink_neg.svg",
    COLOR_LOGO: "https://w.ladicdn.com/source/ladilink-color.svg",
    DOMAIN_CDN: 'https://w.ladicdn.com',
    RESIZE: {
        THUMB: 's150x150',
        MEDIUM: 's600x600',
    },
    SRC_IMAGE: 'https://w.ladicdn.com/s600x600',
    UTM: ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'],
    TIMEZONE: {
        MINUS_TWELVE: {
            code: -12,
            name: 'UTC-12:00',
        },
        MINUS_ELEVEN: {
            code: -11,
            name: 'UTC-11:00',
        },
        MINUS_TEN: {
            code: -10,
            name: 'UTC-10:00',
        },
        MINUS_NINE: {
            code: -9,
            name: 'UTC-9:00',
        },
        MINUS_EIGHT: {
            code: -8,
            name: 'UTC-8:00',
        },
        MINUS_SEVEN: {
            code: -7,
            name: 'UTC-7:00',
        },
        MINUS_SIX: {
            code: -6,
            name: 'UTC-6:00',
        },
        MINUS_FIVE: {
            code: -5,
            name: 'UTC-5:00',
        },
        MINUS_FOUR: {
            code: -4,
            name: 'UTC-4:00',
        },
        MINUS_THREE: {
            code: -3,
            name: 'UTC-3:00',
        },
        MINUS_TWO: {
            code: -2,
            name: 'UTC-2:00',
        },
        MINUS_ONE: {
            code: -1,
            name: 'UTC-1:00',
        },
        ZERO: {
            code: 0,
            name: 'UTC+0:00',
        },
        ONE: {
            code: 1,
            name: 'UTC+1:00',
        },
        TWO: {
            code: 2,
            name: 'UTC+2:00',
        },
        THREE: {
            code: 3,
            name: 'UTC+3:00',
        },
        FOUR: {
            code: 4,
            name: 'UTC+4:00',
        },
        FIVE: {
            code: 5,
            name: 'UTC+5:00',
        },
        SIX: {
            code: 6,
            name: 'UTC+6:00',
        },
        SEVEN: {
            code: 7,
            name: 'UTC+7:00',
        },
        EIGHT: {
            code: 8,
            name: 'UTC+8:00',
        },
        NINE: {
            code: 9,
            name: 'UTC+9:00',
        },
        TEN: {
            code: 10,
            name: 'UTC+10:00',
        },
        ELEVEN: {
            code: 11,
            name: 'UTC+11:00',
        },
        TWELVE: {
            code: 12,
            name: 'UTC+12:00',
        },
        THIRTEEN: {
            code: 13,
            name: 'UTC+13:00',
        },
        FOURTEEN: {
            code: 14,
            name: 'UTC+14:00',
        },
    },
}