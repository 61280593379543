import appConfig, { status } from '../../config/app';
import i18n from '../../i18n';
import baseHelper from '../../helpers/BaseHelper';
import {
    SIGNOUT, GET_USER_INFO
} from '../futures/auth/types';
import { authEndpoint } from '../../config/config';
import { has } from 'lodash';

const cookiesMiddleware = store => next => action => {
    if (has(action, 'new_store_id')) {
        const oldStoreID = baseHelper.getCookie(appConfig.COOKIE.STORE_ID);
        if (oldStoreID != action.new_store_id) {
            baseHelper.setCookie(appConfig.COOKIE.STORE_ID, action.new_store_id);
            window.location.reload();
        }
    }

    if (action.type === SIGNOUT) {
        baseHelper.removeCookie(appConfig.COOKIE.SSID);
        baseHelper.removeCookie(appConfig.COOKIE.STORE_ID);
        window.location.href = authEndpoint.AUTH_SIGNOUT;
    }
    if (action.type === GET_USER_INFO && !action.no_store) {
        try {
            i18n.changeLanguage(action.payload.language);
        } catch(err) {
            console.log(err);
        }
    }

    next(action);
};

export default cookiesMiddleware;
