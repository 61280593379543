import React from 'react';
import { useDispatch } from 'react-redux';
import domainAction from '../../../redux/futures/domain/actions';
import i18n from '../../../i18n';

const ModalSSL = ({ domainName, domainID, callback }) => {
    const dispatch = useDispatch();
    const onSSL = () => {
        if (!domainID) return;
        const data = {
            id: domainID,
        };
        dispatch(domainAction.onSSL(data, callback));
        window.LadiUI.closeModal('modalOnSSL');
    };

    return (
        <div className="ladiui modal" id="modalOnSSL">
            <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width1">
                <div className="ladiui modal-content custom-ldp">
                    <div className="ladiui modal-header-">
                        <label className="ladiui title-text-form-lever1">
                            {i18n.t('DOMAINS.ENABLE_SSL_FOR_DOMAIN_NAME')}
                        </label>
                    </div>
                    <label className="ladiui text on-access-key">
                        {domainName ? (
                            <>
                                {' '}
                                {i18n.t(
                                    'DOMAINS.CONFIRM_ENABLE_SSL_FOR_DOMAIN_NAME'
                                )}{' '}
                                {domainName} {i18n.t('DOMAINS.NO')}?{' '}
                            </>
                        ) : (
                            <></>
                        )}
                    </label>
                    <div className="ladiui modal-footer custom-ldp1">
                        <div></div>
                        <div className="ladiui footer-modal-btn">
                            <button
                                data-dismiss="modal"
                                data-target="modalDelete"
                                className="ladiui btn small txt body3 btn-secondary"
                            >
                                {i18n.t('ACTIONS.REMOVE')}
                            </button>
                            <button
                                className="ladiui btn small txt body3 btn-danger"
                                onClick={() => onSSL()}
                            >
                                {i18n.t('DOMAINS.ACTION_ON_SSL')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalSSL;
