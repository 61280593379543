import React from 'react'
import { useDispatch } from 'react-redux'
import i18n from '../../../i18n'
import tagActions from '../../../redux/futures/tag/actions';

const ModalDeleteTag = ({callback, itemTag, selectedIds, resetSelectedIds}) => {
     const dispatch = useDispatch();

     const deleteTag = () => {
          if(itemTag) {
               dispatch(tagActions.delete({id: itemTag._id}, callback));
          }else{
               dispatch(tagActions.deletes({ids: selectedIds}, callback));
          }
          window.LadiUI.closeModal('modalDelete');
     }

     const resetModalData = (e) => {
          let isClose = false;
          if (!e.target.classList) return;
          const classList = e.target.classList;
          const dismiss = e.target.getAttribute("data-dismiss");
          if (classList.contains('modal') && classList.contains('open'))
               isClose = true;
          if (dismiss && dismiss === 'modal')
               isClose = true;
          if (isClose) {
               resetSelectedIds();
          }
     }

     return (
          <div className="ladiui modal" id="modalDelete" onClick={resetModalData}>
               <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width1">
                    <div className="ladiui modal-content custom-ldp">
                         <div className="ladiui modal-header-">
                              <label className="ladiui title-text-form-lever1">{i18n.t("Bạn muốn xoá Tag?")}</label>
                         </div>
                         <label className="ladiui text on-access-key">
                              {itemTag && itemTag.name ? i18n.t("Bạn có chắc muốn xoá Tag ") + itemTag.name : `${i18n.t("Bạn có chắc muốn xoá những Tag đã chọn")} ?`}
                         </label>
                         <div className="ladiui modal-footer custom-ldp1">
                              <div></div>
                              <div className="ladiui footer-modal-btn">
                                   <button className="ladiui btn btn-secondary bold" data-dismiss="modal" data-target="modalDelete" onClick={resetModalData}>{i18n.t("Huỷ bỏ")}</button>
                                   <button className="ladiui btn btn-danger" onClick={() => deleteTag()}>{i18n.t("Xoá Tag")}</button>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default ModalDeleteTag