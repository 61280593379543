import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import appConfig from '../../config/app';
import i18n from '../../i18n';
import authActions from '../../redux/futures/auth/actions';
import { endpoint } from '../../config/app';
import ModalViewPackage from './ModalViewPackage'

const Topbar = () => {
    const dispatch = useDispatch();
    const userInfo = useSelector(state => state.store.userInfo);
    const [avata, setAvata] = useState('');

    const editImage = () => {
        setAvata('https://w.ladicdn.com/57b167caca57d39c18a1c57e/ladipage-logo-color-1558579165.svg');
    }

    const randomColor = () => {
        var myArray = appConfig.LIST_COLOR
        var rand = myArray[Math.floor(Math.random() * myArray.length)];

        return rand;
    }

    const renderNameImage = (data) => {
        var arr = data.split(' ');
        var name = arr[0][0];
        name += arr.length > 1 ? arr[1][0] : '';
        return name;
    }

    const viewModalPackage = () => {
        window.LadiUI.showModal('ModalViewPackage')
    }

    useEffect(() => {
        if (userInfo.user.avatar == null || userInfo.user.avatar == undefined) {
            setAvata('https://w.ladicdn.com/57b167caca57d39c18a1c57e/ladipage-logo-color-1558579165.svg');
        } else {
            setAvata(userInfo.user.avatar)
        }
    }, [userInfo])

    return (
        <div className="ladiui topbar-menu-right flex-row">
            <div className="ladiui account dropdown">
                <div className="ladiui my-account flex-row dropdown-toggle" data-toggle="dropdown">
                    <div className="ladiui my-account-avata">
                        {
                            userInfo && userInfo.store.ladi_uid === userInfo.user.ladi_uid ?
                                <img src={avata} alt="" onError={() => editImage()} /> :
                                <div className="ladiui boder-avatar">
                                    <div className="ladiui avartar">
                                        <div style={{ backgroundColor: randomColor() }} id='setBackgroudColor' className="ladiui show-avata-name custom">
                                            <div className="ladiui show-text-avata custom">
                                                {renderNameImage(userInfo.store.name)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="ladiui my-account-info">
                        <div className="ladiui my-account-name" style={{ display: 'flex', alignItems: 'center' }}>
                            <div>{userInfo.user.fullname}</div>
                            <i className="ladiui icon icon-prev" style={{ transform: 'rotate(90deg)' }}></i>
                        </div>
                    </div>
                </div>

                <div className="ladiui account dropdown-menu">
                    <div className="ladiui account-content">
                        <div className="ladiui account-header">
                            {
                                avata ?
                                    <div className="ladiui account-avatar">
                                        <img src={avata} alt="" className="ladiui avt" />
                                    </div>
                                    : <></>
                            }
                            <div className="ladiui account-header-text">
                                <span className="ladiui account-name body-1">{userInfo.user.fullname}</span>
                                <span className="ladiui account-email subtitle-2 secondary-text">{userInfo.user.email}</span>
                                <div className="ladiui flex-row package-name" onClick={() => viewModalPackage()}>
                                    <button style={{ cursor: 'pointer' }} className="ladiui status BUSINESS">
                                        <div className={`ladiui status-text`}>{userInfo.store.pkg_name}</div>
                                    </button>
                                    <div className="ladiui account-duration secondary-text caption custom"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ladiui account-body">

                        <div className="ladiui dropdown-item account-body-item active">
                            <div className="ladiui boder-avatar custom">
                                <div className="ladiui avartar" style={{ position: 'relative' }}>
                                    <div style={{ backgroundColor: randomColor() }} id='setBackgroudColor' className="ladiui show-avata-name">
                                        <div className="ladiui show-text-avata">
                                            {renderNameImage(userInfo.store.name)}
                                        </div>
                                        <img src='https://w.ladicdn.com/ladiui/icons/ldicon-colored-circle-check.svg' style={{ width: '16px', height: "16px", position: 'absolute', top: 0, right: '-11px' }} />
                                    </div>
                                </div>
                            </div>
                            <div className="ladiui account-body-content">
                                <span className="ladiui account-name default-text subtitle-2">{userInfo.store.name}</span>
                                <div className="ladiui account-status">
                                    <div className="ladiui my-account-type"></div>
                                    <div className="ladiui account-duration secondary-text caption">{userInfo.user.email}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="ladiui account-content-footer">
                        <div className="ladiui account-footer"></div>
                    </div>
                    <div className="ladiui dropdown-item" onClick={() => window.open(endpoint.ACCOUNT_PROFILE)}>
                        <i className="ladiui icon icon-ldp-setting-builder"></i> {i18n.t('Quản lý tài khoản')}
                    </div>
                    <div className="ladiui dropdown-item" onClick={() => { dispatch(authActions.signOut()) }}>
                        <i className="ladiui icon icon-ldp-log-out"></i>{i18n.t('Đăng xuất')}
                    </div>
                </div>
            </div>
            <ModalViewPackage userInfo={userInfo} />
        </div>

    )
}

export default Topbar