import React, { useEffect } from 'react'
import i18n from '../i18n';
import { map } from 'lodash';
import { Link } from 'react-router-dom';


const SidebarItem = ({ keyStr, classActive, classLi, to, classNav, classIcon, classIconSub, classToggle, title, items, subKeys }) => {
    const paths = window.location.pathname.split('/');
    const classOpen = (keyStr.indexOf(paths[1]) >= 0) ? `${classActive} active` : classActive;
    let currentPath = '';
    if (paths[1]) currentPath += paths[1];
    if (paths[2]) currentPath += '-' + paths[2];


    useEffect(() => {
        const elements = document.getElementsByClassName('ladi-sidebar-submenu');
        if (elements && elements.length > 0) {
            for (let i = 0; i < elements.length; i++) {
                elements[i].classList.remove('active');
            }
        }
        var _item = document.getElementById('submenu-' + currentPath);
        if (_item)
            _item.classList.add('active');
    }, [currentPath])


    return (
        <div>
            <li className={classOpen}>
                {to.indexOf('http') >= 0
                    ?
                    <a className={classLi} data-toggle={classToggle} href={to} target={'_blank'}>
                        <i className={classIcon}></i>
                        <div className={classNav}>
                            <span>{i18n.t(title)}</span>
                            <i className={classIconSub}></i>
                        </div>
                    </a>
                    :
                    <>
                        {
                            items && items.length > 0 ?
                                <a className={classLi} data-toggle={classToggle}>
                                    <i className={classIcon}></i>
                                    <div className={classNav}>
                                        <span>{i18n.t(title)}</span>
                                        <i className={classIconSub}></i>
                                    </div>
                                </a>
                                :
                                <Link to={to} className={classLi} data-toggle={classToggle}>
                                    <i className={classIcon}></i>
                                    <div className={classNav}>
                                        <span>{i18n.t(title)}</span>
                                        <i className={classIconSub}></i>
                                    </div>
                                </Link>
                        }

                        {items && items.length > 0 ?
                            <ul className="ladiui menu-child dropdown-menu dropdown">
                                {
                                    map(items, (subItem, idx) => {
                                        let _subClassActive = 'ladiui subtitle-2 ladi-sidebar-submenu';
                                        if (subKeys.indexOf(currentPath) && currentPath === subItem.key)
                                            _subClassActive += ' active';

                                        return (
                                            <li className="ladiui menu-child-item" key={idx}>
                                                {subItem.to.indexOf('http') < 0 ?
                                                    <Link to={subItem.to} className={_subClassActive} id={'submenu-' + subItem.key.replace('/', '-')}>
                                                        {i18n.t(subItem.title)}
                                                    </Link> :
                                                    <a className={_subClassActive} href={subItem.to} target="_blank">{i18n.t(subItem.title)}</a>
                                                }
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            : <></>
                        }
                    </>
                }
            </li>
        </div>
    )
}

export default SidebarItem
