import moment from 'moment-timezone';
import appConfig from '../config/app';
import { map, isNumber, isBoolean, isEmpty, includes, find, isEqual, reduce } from 'lodash';
import Cookies from 'universal-cookie';
import i18next from 'i18next';
import React from 'react';
class BaseHelper {

    getFromDate(date) {
        if (!this.getMoment().isMoment(date)) {
            return date;
        }

        const _date = date.clone();
        _date.set({
            hour: 0,
            minute: 0,
            second: 0,
            milisecond: 0
        });

        return _date;
    }

    getToDate(date) {
        if (!this.getMoment().isMoment(date)) {
            return date;
        }

        const _date = date.clone();
        _date.set({
            hour: 23,
            minute: 59,
            second: 59,
            milisecond: 999
        });

        return _date;
    }

    getMoment() {
        if (!this.moment) {
            this.moment = moment;
        }

        return this.moment;
    }

    formatStrToDate(str, format = appConfig.DEFAULT_FORMAT_DATE_TIME) {
        try {
            const date = this.getMoment()(str || '');
            if (!date.isValid()) {
                return '';
            }

            return date.format(format);
        } catch (e) {
            return '';
        }
    }

    formatNumber(data, _default = 0) {
        if (!data) {
            return 0;
        }

        return data.toLocaleString('en-EN', { minimumFractionDigits: _default })
    }

    formatMoney(data, prefix = '', postFix = 'đ') {
        return `${prefix} ${this.formatNumber(data)} ${postFix}`;
    }

    getText(data) {
        return data ? data : '';
    }

    hasAllEmptyValues(obj) {
        let result = true;

        map(obj, value => {
            if (value) {
                result = false;
                return;
            }
        });

        return result;
    }

    resetForm(id) {
        window.LadiUI.forEach(window.LadiUI.querySelector(`#${id} .ladiui input`), function (e) {
            e.classList.remove('error');
        });
    }

    /**
  * 
  * @param  {...any} values 
  */
    isEmpty(...values) {
        let result = false;

        map(values, (value) => {
            if (!(isNumber(value) || isBoolean(value)) && isEmpty(value)) {
                result = true;
                return;
            }
        });

        return result;
    }

    getRandomInt(max = 10000) {
        return Math.floor(Math.random() * Math.floor(max));
    }


    /**
     * 
     * @param {*} value 
     * @param {*} _default 
     * @param {*} fix_length 
     */
    parseFloat(value, _default = 0, fix_length = 2) {
        if (this.isEmpty(value) || isNaN(value)) {
            return parseFloat(parseFloat(_default).toFixed(fix_length));
        }

        return parseFloat(parseFloat(value).toFixed(fix_length));
    }

    /**
     *
     * @param {*} value
     * @param {*} _default
     * @description Don't understant why cannot use default variable as 2nd paramter
     */
    parseInt(value, _default = 0) {
        if (this.isEmpty(value) || isNaN(value)) {
            return _default;
        }

        return parseInt(value, 10);
    }

    setCookie(key, value, day = appConfig.COOKIE.EXPIRE) {
        const expire = new Date();
        expire.setDate(new Date().getDate() + day);
        const cookies = new Cookies();
        cookies.set(key, value, { path: '/', expires: expire });
    }

    getCookie(key) {
        const cookies = new Cookies();

        return cookies.get(key);
    }

    removeCookie(key) {
        const cookies = new Cookies();
        cookies.remove(key);
    }

    /**
     * 
     * @param {*} price 
     * @param {*} type 
     * @param {*} value 
     */
    calculateDiscountFee(price, type, value) {
        let fee = 0;
        if (type === appConfig.DISCOUNT.TYPE.PERCENT.value) {
            fee = this.parseFloat(price) * this.parseFloat(value / 100);
        } else if (type === appConfig.DISCOUNT.TYPE.FIXED.value) {
            fee = this.parseFloat(value);
        }

        if (fee < 0) {
            fee = 0;
        }

        if (fee > price) {
            fee = price;
        }

        return fee;
    }

    copyToClipboard(data) {
        const textField = document.createElement('textarea');
        textField.innerHTML = data;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
    };

    getStaffNameById(staffs, ladiUID, meID) {
        if (!ladiUID) {
            return '';
        }

        const matched = find(staffs, item => item.ladi_uid == ladiUID);
        if (!matched) {
            return '';
        }

        if (meID && meID === matched.ladi_uid) {
            return i18next.t('COMMON.ME');
        }
        return matched.name;
    }

    compareShippingAndBilling(shippingAddress, billingAddress) {
        return isEqual(shippingAddress, billingAddress);
    }

    sanitizeUTF8(text) {
        const vnTexts = ['á', 'à', 'ả', 'ã', 'ạ', 'â', 'ấ', 'ầ', 'ẩ', 'ẫ', 'ậ', 'ă', 'ắ', 'ằ', 'ẳ', 'ẵ', 'ặ', 'đ', 'é', 'è', 'ẻ', 'ẽ', 'ẹ', 'ê', 'ế', 'ề', 'ể', 'ễ', 'ệ', 'í', 'ì', 'ỉ', 'ĩ', 'ị', 'ó', 'ò', 'ỏ', 'õ', 'ọ', 'ô', 'ố', 'ồ', 'ổ', 'ỗ', 'ộ', 'ơ', 'ớ', 'ờ', 'ở', 'ỡ', 'ợ', 'ú', 'ù', 'ủ', 'ũ', 'ụ', 'ư', 'ứ', 'ừ', 'ử', 'ữ', 'ự', 'ý', 'ỳ', 'ỷ', 'ỹ', 'ỵ', 'Á', 'À', 'Ả', 'Ã', 'Ạ', 'Â', 'Ấ', 'Ầ', 'Ẩ', 'Ẫ', 'Ậ', 'Ă', 'Ắ', 'Ằ', 'Ẳ', 'Ẵ', 'Ặ', 'Đ', 'É', 'È', 'Ẻ', 'Ẽ', 'Ẹ', 'Ê', 'Ế', 'Ề', 'Ể', 'Ễ', 'Ệ', 'Í', 'Ì', 'Ỉ', 'Ĩ', 'Ị', 'Ó', 'Ò', 'Ỏ', 'Õ', 'Ọ', 'Ô', 'Ố', 'Ồ', 'Ổ', 'Ỗ', 'Ộ', 'Ơ', 'Ớ', 'Ờ', 'Ở', 'Ỡ', 'Ợ', 'Ú', 'Ù', 'Ủ', 'Ũ', 'Ụ', 'Ư', 'Ứ', 'Ừ', 'Ử', 'Ữ', 'Ự', 'Ý', 'Ỳ', 'Ỷ', 'Ỹ', 'Ỵ'];
        const replaceText = ['a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'd', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'e', 'i', 'i', 'i', 'i', 'i', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'o', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'u', 'y', 'y', 'y', 'y', 'y', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'A', 'D', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'E', 'I', 'I', 'I', 'I', 'I', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'O', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'U', 'Y', 'Y', 'Y', 'Y', 'Y'];
        let index;
        for (let i = 0; i < vnTexts.length; i++) {
            index = text.indexOf(vnTexts[i]);
            if (index > -1) {
                text = text.replace(new RegExp(vnTexts[i], 'g'), replaceText[i]);
            }
        }
        return text;
    }

    getAliasName(name) {
        if (!name) {
            return '';
        }

        name = this.sanitizeUTF8(name);
        name = name.replace(/ /g, '-');
        name = name.replace(/[^A-Za-z0-9-_\.]/g, '');
        name = name.replace(/\.+/g, '');
        name = name.replace(/-+/g, '-');
        name = name.replace(/_+/g, '_');
        name = name.toLowerCase();
        return name;
    }

    getTrackingHref = (shipping) => {
        if (!shipping.tracking_number) {
            return <span>N/A</span>
        }

        const partnerCode = shipping.shipping_partner_code;
        switch (partnerCode) {
            case appConfig.SHIPPING_PARTNERS.GHN.CODE:
                return <a target="_blank" href={`https://track.ghn.vn/order/tracking?code=${shipping.tracking_number}`}>{shipping.tracking_number}</a>
            case appConfig.SHIPPING_PARTNERS.GHTK.CODE:
                return <a target="_blank" href={`https://khachhang.giaohangtietkiem.vn/khach-hang/tracking/order/${shipping.tracking_number}`}>{shipping.tracking_number}</a>
            case appConfig.SHIPPING_PARTNERS.VNPOST.CODE:
                return <a target="_blank" href={`http://www.vnpost.vn/vi-vn/dinh-vi/buu-pham?key=${shipping.tracking_number}`}>{shipping.tracking_number}</a>
        }

        return <span>{shipping.tracking_number}</span>
    }

    generateBarCode = (html, option = {}) => {
        const div = document.createElement('div');
        div.innerHTML = html;
        const barcodes = div.querySelectorAll('#barcode');
        if (!barcodes) {
            return div;
        }

        barcodes.forEach(barcode => {
            const val = barcode.getAttribute('val');
            if (val) {
                window.JsBarcode(barcode, val, option);
            }
        });

        return div;
    }

    print = (html, option) => {
        const div = this.generateBarCode(html, option);
        var printwindow = window.frames["print_frame"];
        printwindow.document.body.innerHTML = '<style>@page{size:auto;margin:0}</style>';
        printwindow.document.body.innerHTML += div.innerHTML;
        setTimeout(function () {
            printwindow.document.close();
            printwindow.focus();
            printwindow.print();
            printwindow.close();
        }, 250);
    }

    isDevelopEnv = () => {
        return process.env.REACT_APP_ENVIRONMENT != "production";
    }

    getDisplayName = (name) => {
        if (!name) {
            return ''
        }
        const words = name.split(' ');
        let result = reduce(words, function (result, item) {
            return result + item.substring(0, 1).toUpperCase();
        }, '');

        result = result.substring(0, 2);

        return result;
    }

    checkImageUrl(url, timeout) {
        return new Promise(function (resolve, reject) {
            var _timeout = timeout || 500;
            var img = new Image();

            let timer;

            img.onerror = img.onabort = function () {
                clearTimeout(timer);
                reject('error');
            }

            img.onload = function () {
                clearTimeout(timer);
                resolve('success');
            }

            timer = setTimeout(function () {
                reject('timeout');
            }, _timeout);

            img.src = url;
        });
    }

    decodeEntities(encodedString) {
        var translate_re = /&(nbsp|amp|quot|lt|gt);/g;
        var translate = {
            "nbsp": " ",
            "amp": "&",
            "quot": "\"",
            "lt": "<",
            "gt": ">"
        };
        return encodedString.replace(translate_re, function (match, entity) {
            return translate[entity];
        }).replace(/&#(\d+);/gi, function (match, numStr) {
            var num = parseInt(numStr, 10);
            return String.fromCharCode(num);
        });
    }

    encodeEntities(str) {
        return str.replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
            return '&#' + i.charCodeAt(0) + ';';
        });
    }

    isJson(item) {
        item = typeof item !== "string"
            ? JSON.stringify(item)
            : item;

        try {
            item = JSON.parse(item);
        } catch (e) {
            return false;
        }

        if (typeof item === "object" && item !== null) {
            return true;
        }

        return false;
    }

    checkScope(role, scopes, group, action) {
        if (role == appConfig.ROLE.OWNER.CODE) {
            return true;
        }
        const actions = scopes[group];
        if (actions == true) {
            return true;
        }

        if (Array.isArray(actions) && includes(actions, action)) {
            return true;
        }

        return false;
    }

    momentToDate(moment) {
        return new Date(moment.year(), moment.month(), moment.date(), moment.hours(), moment.minutes(), moment.seconds(), moment.milliseconds());
    }

}

export default new BaseHelper();