import * as types from './types';
import * as commonTypes from '../common/types';
import { cloneDeep, map } from 'lodash';

export default (state = { domains: [] }, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            }
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            }
        }
        case types.LIST_DOMAIN: {
            return {
                ...state,
                action: types.LIST_DOMAIN,
                domains: action.status ? action.payload.items : [],
                totalRecord: action.status ? action.payload.total : 0,
                status: action.status,
                message: action.message,
            }
        }

        case types.LISTALL_DOMAIN: {
            let normalizeDomains = cloneDeep(action.payload.items) || [];
            const domainDefault = map(action.payload.ladi_domain, item => {
                return ({ _id: item, domain: item })
            })
            normalizeDomains = domainDefault.concat(normalizeDomains);

            return {
                ...state,
                action: types.LISTALL_DOMAIN,
                allDomains: action.status ? normalizeDomains : [], // Tat ca domain verify
                domainLadiLink: action.status ? action.payload.ladi_domain : [],
                allReplaceDomains: action.status ? action.payload.items : [],
                status: action.status,
                message: action.message,
            }
        }
        case types.SHOW_DOMAIN: {
            return {
                ...state,
                action: types.SHOW_DOMAIN,
                domain: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            }
        }
        case types.SEARCH_DOMAIN: {
            return {
                ...state,
                action: types.SEARCH_DOMAIN,
                domains: action.status ? action.payload.domains : [],
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_DOMAINS: {
            return {
                ...state,
                action: types.DELETE_DOMAINS,
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_DOMAIN: {
            return {
                ...state,
                action: types.DELETE_DOMAIN,
                status: action.status,
                message: action.message,
            }
        }
        case types.CREATE_DOMAIN: {
            return {
                ...state,
                action: types.CREATE_DOMAIN,
                domain: action.status ? action.payload.domain : {},
                status: action.status,
                message: action.message,
            }
        }
        case types.REPLACE_DOMAIN: {
            return {
                ...state,
                action: types.REPLACE_DOMAIN,
                status: action.status,
                message: action.message,
            }
        }
        case types.UPDATE_DOMAIN: {
            return {
                ...state,
                action: types.UPDATE_DOMAIN,
                status: action.status,
                message: action.message,
            }
        }
        case types.ON_SSL: {
            return {
                ...state,
                action: types.ON_SSL,
                status: action.status,
                message: action.message,
            }
        }
        case types.RELOAD_DOMAIN: {
            return {
                ...state,
                action: types.RELOAD_DOMAIN,
                domain: action.status ? action.payload.domain : {},
                status: action.status,
                message: action.message,
            }
        }
        case types.IMPORT_DOMAIN: {
            return {
                ...state,
                action: types.IMPORT_DOMAIN,
                status: action.status,
                message: action.message,
            }
        }
        case types.EXPORT_DOMAIN: {
            return {
                ...state,
                action: types.EXPORT_DOMAIN,
                status: action.status,
                message: action.message,
            }
        }
        case types.VERIFY_DOMAIN: {
            return {
                ...state,
                action: types.VERIFY_DOMAIN,
                status: action.status,
                message: action.message,
            }
        }
        default:
            return state;
    }
}

