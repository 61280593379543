import React from 'react';
import routes from '../../routes';
import { map } from 'lodash';
import { withTranslation } from 'react-i18next';
import SidebarItem from '../../components/SidebarItem';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import authActions from '../../redux/futures/auth/actions';
class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: ''
        }
    }

    componentDidMount() {
        this.getDomCallback();
    }

    componentWillReceiveProps() {
        this.getDomCallback();
    }

    getDomCallback = function () {
        var frame = document.querySelector(".ladi-popupx-inline iframe");
        if (frame?.clientHeight > 0) {

            document.body.style.setProperty(
                '--notification-height',
                frame?.clientHeight + 'px'
            );
        } else {
            setTimeout(() => {
                this.getDomCallback();
            }, [100])
        }
    };
    render() {
        const { scopes } = this.props;
        const availableItems = routes.getItemByScopes(scopes);

        return (
            <section id="sidebar-left" className="ladiui wrapper">
                <aside className="ladiui aside">
                    <nav className="ladiui nav">
                        <ul className="ladiui menu">
                            <li className="ladiui logo-item logo">
                                <Link to="" className="ladiui flex-row-menu-item">
                                    <img src='https://w.ladicdn.com/ladiui/icons/ldicon-colored-brand-ladilink.svg' alt="" style={{width: '32px', height: '32px'}}/>
                                </Link>
                            </li>
                            <li className="ladiui logo-item logo-hover">
                                <Link to="" className="ladiui flex-row-menu-item">
                                    <img src='https://w.ladicdn.com/ladiui/icons/ldicon-logo-text-ladilink.svg' alt="" />
                                </Link>
                            </li>

                            {
                                map(availableItems, (item) => {
                                    return (
                                        <SidebarItem
                                            keyStr={item.keyStr}
                                            key={item.key}
                                            classActive={item.classActive}
                                            classLi={item.classLi}
                                            to={item.to}
                                            classNav={item.classNav}
                                            classIcon={item.classIcon}
                                            classIconSub={item.classIconSub}
                                            classToggle={item.classToggle}
                                            title={item.title}
                                            items={item.items}
                                            subKeys = {item.subKeys}
                                            children={item.children}
                                        />
                                    )
                                })
                            }

                        </ul>
                    </nav>
                </aside>
            </section>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    signOut: () => dispatch(authActions.signOut()),
});

const mapStateToProps = (state) => ({
    setting: { ...state.setting },
    auth: { ...state.auth },
    staff: { ...state.staff },
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Sidebar));
