import * as types from './types';
import * as commonTypes from '../common/types';

export default (state = {}, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            }
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            }
        }
        case types.LIST_CUSTOMER: {
            return {
                ...state,
                action: types.LIST_CUSTOMER,
                customers: action.status ? action.payload.customers : [],
                totalRecord: action.status ? action.payload.total_record : 0,
                totalPage: action.status ? action.payload.total_page : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.SHOW_CUSTOMER: {
            return {
                ...state,
                action: types.SHOW_CUSTOMER,
                customer: action.status ? action.payload.customer : {},
                status: action.status,
                message: action.message,
            }
        }
        case types.SEARCH_CUSTOMER: {
            return {
                ...state,
                action: types.SEARCH_CUSTOMER,
                customers: action.status ? action.payload.customers : [],
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_CUSTOMERS: {
            return {
                ...state,
                action: types.DELETE_CUSTOMERS,
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_CUSTOMER: {
            return {
                ...state,
                action: types.DELETE_CUSTOMER,
                status: action.status,
                message: action.message,
            }
        }
        case types.CREATE_CUSTOMER: {
            return {
                ...state,
                action: types.CREATE_CUSTOMER,
                customer: action.status ? action.payload.customer : {},
                status: action.status,
                message: action.message,
            }
        }
        case types.UPDATE_CUSTOMER: {
            return {
                ...state,
                action: types.UPDATE_CUSTOMER,
                status: action.status,
                message: action.message,
            }
        }
        case types.RELOAD_CUSTOMER: {
            return {
                ...state,
                action: types.RELOAD_CUSTOMER,
                customer: action.status ? action.payload.customer : {},
                status: action.status,
                message: action.message,
            }
        }
        case types.IMPORT_CUSTOMER: {
            return {
                ...state,
                action: types.IMPORT_CUSTOMER,
                status: action.status,
                message: action.message,
            }
        }
        case types.EXPORT_CUSTOMER: {
            return {
                ...state,
                action: types.EXPORT_CUSTOMER,
                status: action.status,
                message: action.message,
            }
        }
        default:
            return state;
    }
}

