import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const generateSlash = (data) => ({
    type: types.GENERATESLASH,
    meta: {
        endpoint: endpoint.PATH_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const generateInfo = (data) => ({
    type: types.GENERATEINFO,
    meta: {
        endpoint: endpoint.LINK_GENERATE_INFO,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const list = (data) => ({
    type: types.LIST_LINK,
    meta: {
        endpoint: endpoint.LINK_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const show = (linkID) => ({
    type: types.SHOW_LINK,
    meta: {
        endpoint: endpoint.LINK_SHOW,
        method: REQUEST_METHOD.POST,
        body: {
            id: linkID,
        },
        hasAuth: true,
    }
});

const deleteLinks = (linkIDs) => ({
    type: types.DELETE_LINKS,
    meta: {
        endpoint: endpoint.LINK_DELETE_IDS,
        method: REQUEST_METHOD.POST,
        body: {
            ids: linkIDs
        },
        hasAuth: true,
    }
});

const deleteLink = (linkID, cb) => ({
    type: types.DELETE_LINKS,
    meta: {
        endpoint: endpoint.LINK_DELETE_ID,
        method: REQUEST_METHOD.POST,
        body: linkID,
        showNotify: true,
        hasAuth: true,
        cb
    }
});

const create = (data, cb) => ({
    type: types.CREATE_LINK,
    meta: {
        endpoint: endpoint.LINK_CREATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb
    }
});

const update = (data, cb) => ({
    type: types.UPDATE_LINK,
    meta: {
        endpoint: endpoint.LINK_UPDATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb
    }
});

const search = (origin_link) => ({
    type: types.SEARCH_LINK,
    meta: {
        endpoint: endpoint.LINK_SEARCH,
        method: REQUEST_METHOD.POST,
        body: {
            origin_link,
        },
        hasAuth: true,
    }
});

const reload = (linkID) => ({
    type: types.RELOAD_LINK,
    meta: {
        endpoint: endpoint.LINK_SHOW,
        method: REQUEST_METHOD.POST,
        body: {
            id: linkID,
        },
        hasAuth: true,
    }
});

const exportLink = (data) => ({
    type: types.EXPORT_LINK,
    meta: {
        endpoint: endpoint.EXPORT_LINK,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const importLink = (form) => ({
    type: types.IMPORT_LINK,
    meta: {
        endpoint: endpoint.IMPORT_LINK,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    }
});

const resetLink = () => ({
    type: types.RESET_LINK,
});

const upload = (form) => ({
    type: types.UPLOAD_FILE,
    meta: {
        endpoint: endpoint.UPLOAD_FILE,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    }
});

export default {
    generateInfo,
    generateSlash,
    list,
    show,
    deletes: deleteLinks,
    delete: deleteLink,
    create,
    update,
    search,
    reload,
    exportLink,
    importLink,
    resetLink,
    upload,
}