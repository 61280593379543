import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../i18n';

class Modal extends React.Component {

    static propTypes = {
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
        visible: PropTypes.bool.isRequired,
        onCancel: PropTypes.func.isRequired,
        onOk: PropTypes.func,
        zIndex: PropTypes.number,
        closeable: PropTypes.bool,
        style: PropTypes.object,
        isLoading: PropTypes.bool,
        width: PropTypes.number,
        okText: PropTypes.string,
        cancelText: PropTypes.string,
        footer: PropTypes.any,
        bodyStyles: PropTypes.object,
        wrapperClassName: PropTypes.string,
        bodyClassname: PropTypes.string,
        hasFooter: PropTypes.bool,
        disableOk: PropTypes.bool,
        closeComponent: PropTypes.object,
    }

    static defaultProps = {
        disableOk: false,
        closeable: true,
        visible: false,
        isLoading: false,
        cancelText: i18n.t('ACTIONS.CLOSE'),
        okText: i18n.t('ACTIONS.SAVE'),
        hasFooter: true,
    }

    constructor(props) {
        super(props);
    }

    render() {
        const { id, title, children, okText, cancelText, onCancel, onOk, closeable,
            visible, isLoading, zIndex, width, footer, bodyStyles, wrapperClassName,
            bodyClassname, hasFooter, disableOk, closeComponent } = this.props;
        if (!visible) {
            return null;
        }
        return (
            <div ref={this.ref} id={id} className={`ladiui-modal fade visible ${wrapperClassName ? wrapperClassName : ''}`} tabIndex={-1} data-dismiss={id} style={zIndex ? { zIndex } : {}}>
                <div className="ladiui-modal-content" style={width ? { width } : {}}>
                    <div className="ladiui-modal-header">
                        {closeable
                            ?
                            <button onClick={() => onCancel()} className="ladiui-close-modal" data-dismiss={id}><i className="ladi-icon icon-e-remove" data-dismiss={id} /></button>
                            : closeComponent
                        }

                        <h3>{title}</h3>
                    </div>

                    <div className={`ladiui-modal-body ${bodyClassname ? bodyClassname : ''}`} style={bodyStyles ? bodyStyles : {}}>
                        {children}
                    </div>
                    {
                        !hasFooter
                        &&
                        <div style={{ borderTop: 0 }} className="ladiui-modal-footer" />
                    }
                    {
                        hasFooter && footer &&
                        <div className="ladiui-modal-footer">
                            {footer.props.children}
                        </div>
                    }

                    {
                        hasFooter && !footer &&
                        <div className="ladiui-modal-footer">
                            <button onClick={onCancel} className="ladiui button secondary icon"><i className="ladi-icon icon-e-remove" /> {cancelText}</button>
                            <button onClick={onOk} className={`ladiui button primary icon icon-floppy-disk ${isLoading ? 'loader' : ''}`} disabled={isLoading || disableOk}> {okText}</button>
                        </div>
                    }
                </div >
            </div >
        )
    }
}

export default Modal;