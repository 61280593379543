import React, { useEffect, useState } from 'react';

const ModalQrCode = ({ itemLink }) => {

     const [urlImage, setUrlImage] = useState("")

     useEffect(() => {
          if (itemLink) {
               if (itemLink.https == true && itemLink.path) {
                    setUrlImage(`https://${itemLink.domain}/${itemLink.path}`)
               } else {
                    setUrlImage(`http://${itemLink.domain}/${itemLink.path}`)
               }

          }
     }, [itemLink])

     return (
          <div className="ladiui modal" id="modalQR">
               <div className="ladiui modal-dialog modal-dialog-centered" style={{width: '200px'}}>
                    <div className="ladiui modal-content custom-ldp" style={{padding: '0px'}}>
                         <img alt='QR Code' src={`https://api.qrserver.com/v1/create-qr-code/?size=250x250&margin=10&data=` + urlImage} />
                    </div>
               </div>
          </div>
     )
}

export default ModalQrCode