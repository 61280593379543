import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SlidebarItem from './SidebarItem'
import i18n from '../../i18n';
import storeActions from '../../redux/futures/store/actions';

const SettingV3 = () => {
  const dispatch = useDispatch();
  const stores = useSelector(state => state.store.userInfo);
  const [displayTime, setDisplayTime] = useState('')


  const cbList = () => {
    dispatch(storeActions.getUserInfo())
  }

  const defaultConditions = {
    currency: stores.store.currency,
    lang: "vi",
    name: stores.store.name,
    time_zone: stores.store.time_zone,
  }

  const [conditions, setConditions] = useState(defaultConditions);

  const updateStore = () => {
    dispatch(storeActions.update(conditions, cbList));
  }

  const conversionTool = () => {
    switch (stores.store.time_zone) {
      case -12: setDisplayTime('UTC-12:00');
        break;
      case -11: setDisplayTime('UTC-11:00');
        break;
      case -10: setDisplayTime('UTC-10:00');
        break;
      case -8.5: setDisplayTime('UTC-09:30');
        break;
      case -9: setDisplayTime('UTC-09:00');
        break;
      case -8: setDisplayTime('UTC-08:00');
        break;
      case -7: setDisplayTime('UTC-07:00');
        break;
      case -6: setDisplayTime('UTC-06:00');
        break;
      case -5: setDisplayTime('UTC-05:00');
        break;
      case -4: setDisplayTime('UTC-04:00');
        break;
      case -2.5: setDisplayTime('UTC-03:30');
        break;
      case -3: setDisplayTime('UTC-03:00');
        break;
      case -2: setDisplayTime('UTC-02:00');
        break;
      case -1: setDisplayTime('UTC-01:00');
        break;
      case 0: setDisplayTime('UTC-00:00');
        break;
      case 1: setDisplayTime('UTC+01:00');
        break;
      case 2: setDisplayTime('UTC+02:00');
        break;
      case 3: setDisplayTime('UTC+03:00');
        break;
      case 3.5: setDisplayTime('UTC+03:30');
        break;
      case 4: setDisplayTime('UTC+04:00');
        break;
      case 4.5: setDisplayTime('UTC+04:30');
        break;
      case 5: setDisplayTime('UTC+05:00');
        break;
      case 5.5: setDisplayTime('UTC+05:30');
        break;
      case 5.75: setDisplayTime('UTC+05:45');
        break;
      case 6: setDisplayTime('UTC+06:00');
        break;
      case 6.5: setDisplayTime('UTC+06:30');
        break;
      case 7: setDisplayTime('UTC+07:00');
        break;
      case 8: setDisplayTime('UTC+08:00');
        break;
      case 8.75: setDisplayTime('UTC+08:45');
        break;
      case 9: setDisplayTime('UTC+09:00');
        break;
      case 9.5: setDisplayTime('UTC+09:30');
        break;
      case 10: setDisplayTime('UTC+10:00');
        break;
      case 10.5: setDisplayTime('UTC+10:30');
        break;
      case 11: setDisplayTime('UTC+11:00');
        break;
      case 12: setDisplayTime('UTC+12:00');
        break;
      case 12.75: setDisplayTime('UTC+12:45');
        break;
      case 13: setDisplayTime('UTC+13:00');
        break;
      case 14: setDisplayTime('UTC+14:00');
        break;
    }
  }

  useEffect(() => { conversionTool() }, [stores.store.time_zone])


  return (
    <div className="ladiui content-list-landingpage">
      <SlidebarItem active={"settings"} />
      <div className="ladiui content-main">
        <div className="ladiui setting-content-parent">
          <div className="ladiui shared setting-content-right ">
            <div className="ladiui setting-name body-8 bold">{i18n.t("Cài đặt Store")}</div>
            <div className="ladiui form-vertical">
              <span className="ladiui subtitle-3 bold setting-input-name">{i18n.t("Tên Store")}</span>
              <input
                className="ladiui setting form-control"
                defaultValue={stores.store.name}
                onChange={(e) => { setConditions({ ...conditions, name: e.target.value }) }}
              />
            </div>
          </div>
        </div>

        <div className="ladiui setting-content-parent">
          <div className="ladiui shared setting-content-right ">
            <div className="ladiui form-vertical custom">
              <span className="ladiui subtitle-3 bold setting-input-name">{i18n.t("Múi giờ")}</span>
              <div className="ladiui btn-group custom-setting">
                <div className="ladiui dropdown custom-setting">
                  <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown custom-setting" id="dropdownMenuButton3" data-toggle="dropdown" >
                    <i></i>
                    <span className="ladiui dropdown-text">{displayTime}</span>
                  </button>
                  <ul className="ladiui dropdown-menu custom-setting">
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: -12 })}>UTC-12:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: -11 })}>UTC-11:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: -10 })}>UTC-10:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: -8.5 })}>UTC-09:30</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: -9 })}>UTC-09:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: -8 })}>UTC-08:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: -7 })}>UTC-07:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: -6 })}>UTC-06:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: -5 })}>UTC-05:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: -4 })}>UTC-04:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: -2.5 })}>UTC-03:30</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: -3 })}>UTC-03:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: -2 })}>UTC-02:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: -1 })}>UTC-01:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 0 })}>UTC 00:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 1 })}>UTC+01:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 2 })}>UTC+02:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 3 })}>UTC+03:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 3.5 })}>UTC+03:30</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 4 })}>UTC+04:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 4.5 })}>UTC+04:30</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 5 })}>UTC+05:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 5.5 })}>UTC+05:30</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 5.75 })}>UTC+05:45</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 6 })}>UTC+06:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 6.5 })}>UTC+06:30</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 7 })}>UTC+07:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 8 })}>UTC+08:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 8.75 })}>UTC+08:45</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 9 })}>UTC+09:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 9.5 })}>UTC+09:30</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 10 })}>UTC+10:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 10.5 })}>UTC+10:30</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 11 })}>UTC+11:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 12 })}>UTC+12:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 12.75 })}>UTC+12:45</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 13 })}>UTC+13:00</div></li>
                    <li><div className="ladiui dropdown-item" onClick={() => setConditions({ ...conditions, time_zone: 14 })}>UTC+14:00</div></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ladiui setting-content-parent">
          <div className="ladiui shared setting-content-right ">
            <div className="ladiui switch-btn form-vertical">
              <button className="ladiui btn btn-primary bold" onClick={() => updateStore()}>
                {i18n.t("Cập nhật")}
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default SettingV3