import * as types from './types';
import * as commonTypes from '../common/types';
import { cloneDeep } from 'lodash';

export default (state = { tags: [], newTags: [] }, action) => {
    switch (action.type) {
        case commonTypes.ASYNC_START: {
            if (!types[action.waitting]) {
                return state;
            }
            return {
                ...state,
                loading: true,
                waitting: action.waitting,
                action: commonTypes.ASYNC_START,
            }
        }
        case commonTypes.ASYNC_END: {
            if (!types[action.done]) {
                return state;
            }

            return {
                ...state,
                waitting: null,
                loading: false,
                action: commonTypes.ASYNC_END,
            }
        }
        case types.LIST_TAG: {
            const normalizeTag = cloneDeep(action.payload.items) || [];
            return {
                ...state,
                action: types.LIST_TAG,
                newTags: action.status ? action.payload.items : [],
                tags: action.status ? action.payload.items : [],
                tagSearch: action.status ? normalizeTag : [],
                tagsLink: action.status ? action.payload.itemsLink : [],
                totalRecord: action.status ? action.payload.total : 0,
                status: action.status,
                message: action.message,
            }
        }
        case types.SHOW_TAG: {
            return {
                ...state,
                action: types.SHOW_TAG,
                tag: action.status ? action.payload : {},
                status: action.status,
                message: action.message,
            }
        }
        case types.SEARCH_TAG: {
            return {
                ...state,
                action: types.SEARCH_TAG,
                tags: action.status ? action.payload.tags : [],
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_TAGS: {
            return {
                ...state,
                action: types.DELETE_TAGS,
                status: action.status,
                message: action.message,
            }
        }
        case types.DELETE_TAG: {
            return {
                ...state,
                action: types.DELETE_TAG,
                status: action.status,
                message: action.message,
            }
        }
        case types.CREATE_TAG: {
            return {
                ...state,
                action: types.CREATE_TAG,
                tag: action.status ? action.payload.tag : {},
                status: action.status,
                message: action.message,
            }
        }
        case types.UPDATE_TAG: {
            return {
                ...state,
                action: types.UPDATE_TAG,
                status: action.status,
                message: action.message,
            }
        }
        case types.RELOAD_TAG: {
            return {
                ...state,
                action: types.RELOAD_TAG,
                tag: action.status ? action.payload.tag : {},
                status: action.status,
                message: action.message,
            }
        }
        case types.IMPORT_TAG: {
            return {
                ...state,
                action: types.IMPORT_TAG,
                status: action.status,
                message: action.message,
            }
        }
        case types.EXPORT_TAG: {
            return {
                ...state,
                action: types.EXPORT_TAG,
                status: action.status,
                message: action.message,
            }
        }
        default:
            return state;
    }
}

