import React from 'react';
import authActions from '../redux/futures/auth/actions';
import storeActions from '../redux/futures/store/actions';
import * as storeTypes from '../redux/futures/store/types';
import { connect } from 'react-redux';
import AppLayout from './apps/AppLayout';
import LoadingScene from '../components/LoadingScene';
import { withRouter, Route, Switch } from 'react-router-dom';
import '../../src/assets/css/custom.css';
// import '../../src/assets/css/ladiui.css';
import '../assets/js/custom'
// import '../assets/js/ladiui'
// import '../assets/js/location.all.min'
import '../../src/assets/css/icon.css';

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            message: ''
        }
    }

    componentDidMount() {
        this.props.getUserInfo();
    }

    componentWillReceiveProps(nextProps) {
        if (this.props === nextProps) {
            return;
        }

        if (this.props.storeReducer.action != nextProps.storeReducer.action) {
            if (nextProps.storeReducer.action === storeTypes.GET_USER_INFO) {
                if (nextProps.storeReducer.status) {
                    if (!nextProps.storeReducer.noStore) {
                        const ladiUID = nextProps.storeReducer.userInfo.ladi_uid;

                        if (nextProps.storeReducer.userInfo.store) {
                            const storeID = nextProps.storeReducer.userInfo.store.store_id;
                            this.props.signInSuccess(ladiUID, storeID);
                        }
                    }
                } else {
                    this.setState({
                        message: nextProps.storeReducer.message
                    })
                }
            }
        }
    }

    render() {
        const isLoading = this.props.storeReducer.loading && (this.props.storeReducer.waitting == storeTypes.GET_USER_INFO);
        if (isLoading) {
            return <LoadingScene />;
        }
        if (!this.props.storeReducer.hasStore) {
            return <div className="text-center" style={{ marginTop: "15%" }}>
                <h1 style={{ fontSize: "15px" }}>{this.state.message}</h1>
                <p style={{ marginTop: "10px" }}> Vui lòng Livechat để được hỗ trợ!</p>
                <a className="brand-button" href="https://ladipage.vn/" target="_blank">Quay về trang chủ</a>
            </div>
        }

        return (
            <Switch>
                {
                    this.props.storeReducer.hasStore ? <Route component={AppLayout} /> : null
                }
            </Switch>
        )

    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        getUserInfo: () => dispatch(storeActions.getUserInfo()),
        signInSuccess: (ladiUID, storeID) => dispatch(authActions.signInSuccess(ladiUID, storeID)),
    }
}

const mapStateToProps = (state) => ({
    storeReducer: { ...state.store }
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));