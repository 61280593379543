import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../../i18n';
import appConfig from '../../config/app';
import domainActions from '../../redux/futures/domain/actions';
import tagActions from '../../redux/futures/tag/actions';
import NoData from '../../components/NoData';
import ModalCreate from './components/ModalCreate';
import ModalUpdate from './components/ModalUpdate';
import ConfirmModal from '../../components/ConfirmModal';
import baseHelper from '../../helpers/BaseHelper';
import LadiPagination from '../../components/LadiPagination';
import SlidebarItem from '../../pages/settingV3/SidebarItem';
import ModalSSL from "./components/ModalSSL";

const Domain = () => {
    const dispatch = useDispatch();
    const domains = useSelector(state => state.domain.domains);
    const totalRecord = useSelector(state => state.link.totalRecord);

    const [keyWord, setKeyWord] = useState('')
    const [selecteDomain, setSelectedDomain] = useState();

    const [conditions, setConditions] = useState({
        domain: "",
        keyword: "",
        limit: 10,
        page: 1,
        sort: {},
    });

    const [payLoadTag, setPayLoadTag] = useState({
        keyword: "",
        page: 1,
        limit: 100,
    });

    const [domainID, setDomainID] = useState(null);
    const [domainName, setDomainName] = useState('');

    const listDomain = () => {
        dispatch(domainActions.list(conditions));
    }

    const listTags = () => {
        dispatch(tagActions.list(payLoadTag));
    }

    const onSelectedLimit = (limit) => {
        setConditions({ ...conditions, limit: limit });
    }

    const onPageChange = (pageNumber) => {
        setConditions({ ...conditions, page: pageNumber });
    }

    const openVerify = (domain) => {
        const data = {
            domain: domain.domain,
            id: domain._id
        }
        dispatch(domainActions.verify(data));
    }

    const deleteDomain = (domain) => {
        dispatch(domainActions.delete(domain._id));
        listDomain();
        window.LadiUI.closeModal('confirm-domain-delete');
    }

    const openModalReplace = () => {
        this.setState({
            mode: appConfig.FORM_MODE.REPLACE,
            currentDomainInfo: null,
            isShowModalDomain: true,
        });
    }

    const onCreateDomain = () => {
        window.LadiUI.showModal('modalCreateDomain');
    }

    const openDeleteDomain = (domain) => {
        setSelectedDomain(domain);
        window.LadiUI.showModal('confirm-domain-delete');
    }

    const onUpdateDomain = (domain) => {
        setSelectedDomain(domain);
        window.LadiUI.showModal('modalUpdateDomain');
    }

    useEffect(() => {
        listDomain();
    }, [conditions])

    useEffect(() => {
        listTags();
    }, [payLoadTag])

    const statusSSl = (data) => {
        switch (data) {
             case "OFF_SSL": return i18n.t("DOMAINS.SSL_OFF")
             case "ON_SSL_ERROR": return i18n.t("DOMAINS.INITIALIZE_ERROR")
             case "ON_QUEUE": return i18n.t("DOMAINS.ON_INITIALIZE")
             case "ON_SSL_SUCCESS": return i18n.t("DOMAINS.SSL_ON")
             default: return i18n.t("DOMAINS.SSL_OFF")
        }
    }

    const onSSL = (domain) => {
        setDomainID(domain._id);
        setDomainName(domain.domain);
        window.LadiUI.showModal('modalOnSSL');
    }

    const renderDomainItem = (item) => {
        return <tr className="ladiui table-vertical main" key={item._id}>
            <td>
                <a onClick={() => this.onUpdateDomain(item)}>{`${baseHelper.getText(item.domain)} ${baseHelper.getText(item.first_name)}`}</a>
            </td>
            <td className="text-center">{item.total_link}</td>
            <td className="text-center">{item.is_verified == true ? 'Đã xác thực' : 'Chưa xác thực'}</td>
            <td>{item.is_ssl == true ? i18n.t("DOMAINS.SSL_ON") : statusSSl(item.ssl_status)}</td>
            <td>{baseHelper.formatStrToDate(item.created_at)}</td>
            <td>{baseHelper.formatStrToDate(item.updated_at)}</td>
            <td className="ladiui-dropdown text-right">
                <div className="ladiui btn-group">
                    <div className="ladiui dropdown">
                        <button data-toggle="dropdown" className="ladiui-btn-dropdown dropdown-toggle"><i
                            className="ladiui icon icon-ldp-dot"></i></button>
                        <ul className="ladiui dropdown-menu custom-dropdown-menu">
                            <li><a className="ladiui dropdown-item" onClick={() => onUpdateDomain(item)}><i
                                className="ladiui icon icon-ldp-setting-builder"></i> {i18n.t('ACTIONS.EDIT')}</a></li>
                            {item.is_verified == false
                                ?
                                <li><a className="ladiui dropdown-item" onClick={() => openVerify(item)}><i
                                    className="ladiui icon text-color icon-ldp-ssl"></i> {i18n.t('ACTIONS.VERIFIED')}
                                </a></li>
                                :
                                null
                            }

                            {item.is_verified &&
                            !item.is_ssl  ? (
                                <li>
                                    <a
                                        className="ladiui dropdown-item"
                                        onClick={() => onSSL(item)}
                                    >
                                        <i className="ladiui icon text-color new-ldicon-shield"></i>
                                        {i18n.t('DOMAINS.ACTION_ON_SSL')}
                                    </a>
                                </li>
                            ) : (
                                <></>
                            )}

                            <li><a className="ladiui dropdown-item" onClick={() => openDeleteDomain(item)}><i
                                className="ladiui icon text-color icon-ldp-delete"></i> {i18n.t('ACTIONS.DELETE')}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </td>
        </tr>
    }

    return (
        <>
            <div className="ladiui content-list-landingpage">
                <SlidebarItem active={"domain"}/>
                <div className="ladiui content-main">
                    <div className="domain-page">
                        <div className="ladiui content-main-title flex-row">
                            <div className="ladiui main-title">{i18n.t("Danh sách tên miền")}</div>
                            <button className="ladiui btn btn-primary" onClick={() => onCreateDomain()}><img
                                className="ladiui btn-custom-img"
                                src='https://w.ladicdn.com/ladiui/ladipage/icon-ldp-add.svg'
                                alt=""/> {i18n.t('DOMAINS.ADD_DOMAINS')}</button>
                        </div>

                        <div className="ladiui content-main-tool flex-row">
                            <div className="ladiui search-group dropdown">
                                <input
                                    autoComplete='off'
                                    className="ladiui search-field form-control"
                                    style={{minWidth: 270}}
                                    aria-expanded={false}
                                    name='keyword'
                                    placeholder={i18n.t("Tìm kiếm")}
                                    onChange={(e) => {
                                        setKeyWord(e.target.value);
                                        if (e.target.value === '') {
                                            setConditions({...conditions, keyword: '', page: 1})
                                        }
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') setConditions({...conditions, keyword: keyWord, page: 1})
                                    }}
                                />
                                <i className="ladiui icon icon-search"
                                   onClick={() => setConditions({...conditions, keyword: keyWord, page: 1})}></i>
                            </div>
                        </div>

                        <table className="ladiui table text-left">
                            <thead>
                            <tr className='ladiui table-vertical header'>
                                <th name="domain" className={`text-left`}>{i18n.t('DOMAINS.DOMAIN')}</th>
                                <th name="total" className={`text-center`}>{i18n.t('DOMAINS.TOTAL_LINK')}</th>
                                <th name="total" className={`text-center`}>{i18n.t('STATUS.ALL_STATUS')}</th>
                                <th>Trạng thái SSL</th>
                                <th name="createdat">{i18n.t('COMMON.CREATED_AT')}</th>
                                <th name="updatedat">{i18n.t('COMMON.UPDATED_AT')}</th>
                                <th className="text-right" style={{minWidth: 150}}>{i18n.t('ACTIONS.TITLE')}</th>
                            </tr>
                            </thead>
                            <tbody>{
                                domains && domains.length > 0 ?
                                    domains.map(item => (
                                        renderDomainItem(item)
                                    ))
                                    :
                                    <NoData column={7} status={i18n.t("Không tìm thấy Domain nào")}/>
                            }
                            </tbody>
                        </table>

                        {totalRecord > 0 &&
                            <LadiPagination
                                conditionsProp={conditions}
                                listName={i18n.t("Tên miền")}
                                items={domains}
                                total={totalRecord}
                                limitAction={onSelectedLimit}
                                pageAction={onPageChange}/>
                        }
                    </div>
                </div>
                <ModalCreate
                    callback={listDomain}
                />

                <ModalUpdate
                    currentDomain={selecteDomain}
                    callback={listDomain}
                />

                <ModalSSL
                    domainID={domainID}
                    domainName={domainName}
                    callback={listDomain}
                />

                <ConfirmModal
                    id="confirm-domain-delete"
                    title={i18n.t('DOMAINS.MSG_DELETE_DOMAINS_TITLE')}
                    content={i18n.t('DOMAINS.MSG_DELETE_DOMAINS_CONTENT')}
                    cancelText={i18n.t('ACTIONS.CANCEL')}
                    okText={i18n.t('ACTIONS.DELETE')}
                    onOk={() => deleteDomain(selecteDomain)}
                />
            </div>
        </>
    )
}

export default Domain