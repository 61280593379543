import React from 'react'
import appConfig from '../config/app';

const NoData = ({ status, column = 3 }) => {
     return (<tr>
          <td colSpan={column}>
               <div className='text-center'>
                    <img className='ladiui icon-not-foder' src={appConfig.URL_IMAGE.ICON_NOT_FODER} alt="" />
                    <span style={{ color: 'var(--main-mute)' }}>{status}</span>
               </div>
          </td>
     </tr>
     )
}

export default NoData