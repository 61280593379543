import React from 'react'
import i18n from '../../i18n';
import { Link } from 'react-router-dom'

const SlidebarItem = ({ active }) => {
     return (
          <div className="ladiui setting-content-left">
               {
                    active === "settings"
                         ?
                         <div className="ladiui setting-content">
                              <Link to='/settings'><span className="ladiui subtitle-2 content-tag-text active">{i18n.t("Cài đặt chung")}</span></Link>
                         </div>
                         :
                         <div className="ladiui setting-content">
                              <Link to='/settings'><span className="ladiui subtitle-2 content-tag-text">{i18n.t("Cài đặt chung")}</span></Link>
                         </div>
               }
               {
                    active === "domain"
                         ?
                         <div className="ladiui setting-content">
                              <Link to='/settings/domain'><span className="ladiui subtitle-2 content-tag-text active">{i18n.t("Tên miền")}</span></Link>
                         </div>
                         :
                         <div className="ladiui setting-content">
                              <Link to='/settings/domain'><span className="ladiui subtitle-2 content-tag-text">{i18n.t("Tên miền")}</span></Link>
                         </div>
               }
               {
                    active === "addApi"
                         ?
                         <div className="ladiui setting-content">
                              <Link to='/settings/addApi'><span className="ladiui subtitle-2 content-tag-text active">{i18n.t("Tích hợp API")}</span></Link>
                         </div>
                         :
                         <div className="ladiui setting-content">
                              <Link to='/settings/addApi'><span className="ladiui subtitle-2 content-tag-text">{i18n.t("Tích hợp API")}</span></Link>
                         </div>
               }
               {
                    active === "infoPackage"
                         ?
                         <div className="ladiui setting-content">
                              <Link to='/settings/infoPackage'><span className="ladiui subtitle-2 content-tag-text active">{i18n.t("Thông tin gói sử dụng")}</span></Link>
                         </div>
                         :
                         <div className="ladiui setting-content">
                              <Link to='/settings/infoPackage'><span className="ladiui subtitle-2 content-tag-text">{i18n.t("Thông tin gói sử dụng")}</span></Link>
                         </div>
               }
          </div>
     )
}

export default SlidebarItem