import React, { Component } from 'react';
import './assets/css/reset.css';
import './assets/css/fonts.css';
import Layout from './layout/Layout';
import { authEndpoint } from './config/config';
import appConfig from './config/app';
import queryString from 'query-string';
import baseHelper from './helpers/BaseHelper';
import 'moment/locale/vi';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

class App extends Component {
  constructor(props) {
    super(props);

    const parser = queryString.parse(this.props.location.search);
    if (parser.token) {
      baseHelper.setCookie(appConfig.COOKIE.SSID, parser.token);
      delete parser.token;
      const restParam = queryString.stringify(parser);
      props.history.push(`?${restParam}`);
    }
  }

  render() {
    let token = baseHelper.getCookie(appConfig.COOKIE.SSID);
    if (!token) {
      let redirectUrl = authEndpoint.AUTH_SIGNIN;
      if (this.props.location.search) {
        const parser = queryString.parse(this.props.location.search);
        const redirectParser = queryString.parseUrl(redirectUrl);
        const callbackUrl = redirectParser.query.callback_url;
        const parserCallbackUrl = queryString.parseUrl(callbackUrl);
        parserCallbackUrl.query= {
          ...parserCallbackUrl.query,
          ...parser
        }

        const newCallbackUrl = queryString.stringifyUrl(parserCallbackUrl)

        redirectParser.query.callback_url = newCallbackUrl;
        redirectUrl = queryString.stringifyUrl(redirectParser);
      }

      window.location.href = redirectUrl;
      return;
    }
    return <Layout />
  }
}
export default App;