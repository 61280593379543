import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import i18n from '../../../i18n'
import tagActions from '../../../redux/futures/tag/actions';

function ModalCreateTag({ callback, itemTag, type }) {
     const dispatch = useDispatch();

     const [tag, setTag] = useState({
          name: '',
     })

     const create = () => {
          if (type && type == "CREATE" && tag && tag.name === '' || tag.name === undefined ) {
               setTimeout(() => {
                    window.LadiUI.showInputMessage(document.getElementById("inputNameTag"), true, true, i18n.t("Vui lòng nhập vào trường này"));
               }, 1);
               return;
          }

          if (type && type == "UPDATE" && dataUpdate && dataUpdate.name == '' || dataUpdate.name == undefined) {
               setTimeout(() => {
                    window.LadiUI.showInputMessage(document.getElementById("inputNameTag"), true, true, i18n.t("Vui lòng nhập vào trường này"));
               }, 1);
               return;
          }

          if (type == "CREATE") {
               dispatch(tagActions.create(tag, callback));
          }

          if (type == "UPDATE") {
               dispatch(tagActions.update(dataUpdate, callback));
          }
          window.LadiUI.closeModal('createTag');
          clearInput()
     }

     const clearInput = () => {
          setTag({ name: "" })
     }

     const [dataUpdate, setDataUpdate] = useState({
          alias: '',
          created_at: '',
          creator_id: '',
          is_delete: '',
          name: '',
          owner_id: '',
          store_id: '',
          total_link: '',
          updated_at: '',
          _id: '',
     })

     useEffect(() => {
          if (!itemTag) {
               return
          } else {
               setDataUpdate({
                    alias: itemTag.alias,
                    created_at: itemTag.created_at,
                    creator_id: itemTag.creator_id,
                    is_delete: itemTag.is_delete,
                    name: itemTag.name,
                    owner_id: itemTag.owner_id,
                    store_id: itemTag.store_id,
                    total_link: itemTag.total_link,
                    updated_at: itemTag.updated_at,
                    _id: itemTag._id,
               })
          }
     }, [itemTag])

     return (
          <div className="ladiui modal" id="createTag">
               <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
                    <div className="ladiui modal-content custom-ldp">
                         <div className="ladiui modal-header custom-ldp">
                              <label className="ladiui title-text-form-lever1">
                                   {type && type == "UPDATE" ? i18n.t("Chỉnh sửa Tag") : i18n.t("Tạo Tag mới")}
                              </label>
                              <button type="button" data-dismiss="modal" data-target="createTag" className="ladiui modal-close" onClick={() => clearInput()}>
                                   <i className="ladiui icon icon-ldp-close"></i>
                              </button>
                         </div>
                         <div className="ladiui form-group-custom">
                              <label className="ladiui title-text-form-lever2" htmlFor="exampleInputPassword1">{i18n.t("Tên Tag")}</label>
                              <input
                                   id='inputNameTag'
                                   className="ladiui form-control"
                                   placeholder="Nhập Tag"
                                   value={type && type == "UPDATE" ? dataUpdate.name : tag.name}
                                   onChange={e => type && type == "UPDATE" ? setDataUpdate({ ...dataUpdate, name: e.target.value }) : setTag({ name: e.target.value })}
                              />
                         </div>
                         <div className="ladiui modal-footer custom-ldp">
                              <div></div>
                              <div className="ladiui footer-modal-btn">
                                   <button data-dismiss="modal" data-target="createTag" className="ladiui btn btn-secondary bold" onClick={() => clearInput()}>{i18n.t("Huỷ bỏ")}</button>
                                   <button className="ladiui btn btn-primary" onClick={() => create()}>
                                        {type && type == "UPDATE" ? i18n.t("Cập nhật") : i18n.t("Tạo Tag")}
                                   </button>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default ModalCreateTag