import axios from 'axios';
import * as commonTypes from '../futures/common/types';
import { SIGNOUT } from '../futures/auth/types';
import appConfig, { status } from '../../config/app';
import baseHelper from '../../helpers/BaseHelper';
import { has, isFunction } from 'lodash';

export const REQUEST_METHOD = {
    GET: 'get',
    POST: 'post',
}

const serviceMiddleware = store => next => action => {
    next({ type: commonTypes.ASYNC_START, waitting: action.type });
    if (action.meta) {
        const { endpoint, body, method, hasAuth, form, hasStoreID, showNotify ,cb} = action.meta;
        if (form) {
            let data = {};
            for (let [key, prop] of form) {
                data[key] = JSON.parse(JSON.stringify(prop));
            }

            data = JSON.parse(JSON.stringify(data, null, 2));
        }


        const req = {
            method: method,
            url: endpoint,
            headers: requestHeaders(hasAuth, form, hasStoreID),
            data: body ? body : form
        };

        axios(req).then(function (response) {
            const { data } = response;
            if (has(response.headers, 'store-id')) {
                action.new_store_id = response.headers['store-id'];
            }

            switch (data.code) {
                case status.OK:
                case status.CREATED:
                    action.status = true;
                    action.payload = data.data;
                    action.message = data.message;

                    // Handle cache data
                    cacheData(action);
                    break;
                case status.UNAUTHORIZED:
                    baseHelper.removeCookie(appConfig.COOKIE.SSID);
                    baseHelper.removeCookie(appConfig.COOKIE.STORE_ID);

                    next({ type: SIGNOUT });
                    return;
                case status.FORBIDDEN:
                    action.status = false;
                    action.message = data.message;
                    break;
                default:
                    action.status = false;
                    action.message = data.message;
                    break;
            }
            if (showNotify) {
                if (action.status) {
                    window.LadiUI.toast('success', 'Thông báo', data.message, 5000, 'bottom-left');
                } else {
                    window.LadiUI.showErrorMessage('Thông báo', data.message, 'OK');
                }
            }

            if (isFunction(cb)) {
                cb.response = response;
                cb();
            }

            next(action);
            next({ type: commonTypes.ASYNC_END, done: action.type });
        }).catch(function (error) {
            action.status = false;
            let message = error.message;
            if (error.response && error.response.data)
                message = error.response.data.message;
            action.message = message;
            window.LadiUI.showErrorMessage('Thông báo', message, 'OK');
            next({ type: commonTypes.ASYNC_END, done: action.type });
        });

        return;
    }

    next(action);
    next({ type: commonTypes.ASYNC_END, done: action.type });
}

function requestHeaders(hasAuth = true, form, hasStoreID = true) {
    const headers = (form)
        ?
        {
            'Content-Type': 'multipart/form-data',
        }
        :
        {
            'Content-Type': 'application/json',
        }

    if (hasStoreID) {
        const storeID = baseHelper.getCookie(appConfig.COOKIE.STORE_ID);
        if (storeID) {
            headers['store-id'] = storeID;
        }
    }

    if (!hasAuth) {
        return headers;
    }

    const token = baseHelper.getCookie(appConfig.COOKIE.SSID);
    headers.authorization = token;

    return headers;
}

async function cacheData(action) {
}


export default serviceMiddleware;
