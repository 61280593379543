import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SlidebarItem from './SidebarItem'
import i18n from '../../i18n';
import apiActions from '../../redux/futures/api/actions';

const AddApi = () => {
     const dispatch = useDispatch();
     const api = useSelector(state => state.api.apiKey);


     const apiKey = () => {
          dispatch(apiActions.getApiInfo())
     }

     const copyClipboard = (id) => {
          const copyText = document.getElementById(id);
          navigator.clipboard.writeText(copyText.value);
          window.LadiUI.toast('success', 'Thông báo', i18n.t("Sao chép thành công"), 5000, 'bottom-left');
     };

     const handleRefreshApiKey = () => {
          dispatch(apiActions.getApiInfo(true))
     }

     useEffect(() => {
          apiKey();
     }, [])

     return (
          <div className="ladiui content-list-landingpage">
               <SlidebarItem active={"addApi"} />
               <div className="ladiui content-main">
                    <div className="ladiui add-api">
                         <div className="ladiui shared setting-content-right ">
                              <div className="ladiui setting-name body-8 bold">{i18n.t("Tích hợp API")}</div>
                              <span className="ladiui secondary-text body-3">{i18n.t("Tạo và sử dụng API key bên dưới để tích hợp kết nối với cổng API của LadiLink")}</span>

                              <div className="ladiui form-group flex-row" style={{ gap: '12px' }}>
                                   <div className="box-api-key">
                                        <i className="ladiui icon icon-ldp-on-access-key"></i>
                                        <input autoComplete="off" type="text" className="ladiui form-control"
                                               id="api-key" name="api-key" readOnly="" value={api}/>
                                        <div>
                                             <i
                                                 className="ladiui icon-ldp icon-refresh"
                                                 onClick={() => handleRefreshApiKey()}
                                             ></i>
                                        </div>
                                        <div>
                                             <i className="ladiui icon-ldp icon-copy"
                                                onClick={() => copyClipboard('api-key')}></i>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     )
}

export default AddApi;