import { REQUEST_METHOD } from '../../middlewares/services';

import * as types from './types';
import { endpoint } from '../../../config/app';

const list = (data) => ({
    type: types.LIST_TAG,
    meta: {
        endpoint: endpoint.TAG_LIST,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const show = (tagID) => ({
    type: types.SHOW_TAG,
    meta: {
        endpoint: endpoint.TAG_SHOW,
        method: REQUEST_METHOD.POST,
        body: {
            id: tagID,
        },
        hasAuth: true,
    }
});

const deleteTags = (tagIDs, cb) => ({
    type: types.DELETE_TAGS,
    meta: {
        endpoint: endpoint.TAG_DELETE_IDS,
        method: REQUEST_METHOD.POST,
        body: tagIDs,
        hasAuth: true,
        showNotify: true,
        cb
    }
});

const deleteTag = (tagID, cb) => ({
    type: types.DELETE_TAGS,
    meta: {
        endpoint: endpoint.TAG_DELETE_ID,
        method: REQUEST_METHOD.POST,
        body: tagID,
        hasAuth: true,
        showNotify: true,
        cb
    }
});

const create = (name, cb) => ({
    type: types.CREATE_TAG,
    meta: {
        endpoint: endpoint.TAG_CREATE,
        method: REQUEST_METHOD.POST,
        body: name,
        hasAuth: true,
        showNotify: true,
        cb
    }
});

const update = (data,cb) => ({
    type: types.UPDATE_TAG,
    meta: {
        endpoint: endpoint.TAG_UPDATE,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
        showNotify: true,
        cb
    }
});

const search = (name) => ({
    type: types.SEARCH_TAG,
    meta: {
        endpoint: endpoint.TAG_SEARCH,
        method: REQUEST_METHOD.POST,
        body: {
            search: name,
        },
        hasAuth: true,
    }
});

const reload = (tagID) => ({
    type: types.RELOAD_TAG,
    meta: {
        endpoint: endpoint.TAG_SHOW,
        method: REQUEST_METHOD.POST,
        body: {
            id: tagID,
        },
        hasAuth: true,
    }
});

const exportTag = (data) => ({
    type: types.EXPORT_TAG,
    meta: {
        endpoint: endpoint.EXPORT_TAG,
        method: REQUEST_METHOD.POST,
        body: data,
        hasAuth: true,
    }
});

const importTag = (form) => ({
    type: types.IMPORT_TAG,
    meta: {
        endpoint: endpoint.IMPORT_TAG,
        method: REQUEST_METHOD.POST,
        form,
        hasAuth: true,
    }
});

export default {
    list,
    show,
    deletes: deleteTags,
    delete: deleteTag,
    create,
    update,
    search,
    reload,
    exportTag,
    importTag,
}