import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../../../i18n';
import Tag from '../../../components/Tag';
import linkActions from '../../../redux/futures/link/actions';
import domainActions from '../../../redux/futures/domain/actions';
import appConfig from '../../../config/app';
import parseUrl from 'is-valid-http-url';
import queryString from 'query-string';

const ModalCreateLink = ({ tags, callback, itemLink }) => {
     const dispatch = useDispatch();
     const tagRef = useRef();

     const [originLink, setOriginLink] = useState({ value: "" });
     const [domain, setDomain] = useState("ldp.ink");

     const typeSlash = useSelector(state => state.link.dataSlash);
     const generateInfo = useSelector(state => state.link.data);
     const domains = useSelector(state => state.domain.domains);
     const [showSubModal, setShowSubModal] = useState('none') // trạng thái của modal
     const [showUTM, setShowUTM] = useState('none') // trạng thái của UTM

     // Tiền tố
     const [campaignSource, setCampaignSource] = useState();
     const [campaignMedium, setCampaignMedium] = useState();
     const [campaignName, setCampaignName] = useState();
     const [campaignTerm, setCampaignTerm] = useState();
     const [campaignContent, setCampaignContent] = useState();

     const [dataCreate, setDataCreate] = useState({
          description: "",
          domain: domain,
          image: "",
          ladi_path: "",
          origin_link: "",
          tags: [],
          title_link: "",
     })

     // Thời gian chờ nhập url
     var timeout_id = null;
     var time = 500;
     var time2 = 300;

     const delayTimeOut = (e) => {
          e.persist()
          clearTimeout(timeout_id);
          timeout_id = setTimeout(() => {
               setOriginLink({ value: e.target.value })
          }, time);
     }

     const handleChange = (value) => {
          if (value && value != "") {
               if (parseUrl(value)) {
                    setShowSubModal("block");
                    setShowUTM("block");
                    document.getElementById("url").style.marginBottom = "0px"
                    const parse = queryString.parseUrl(value);
                    setCampaignSource(parse.query.utm_source)
                    setCampaignMedium(parse.query.utm_medium)
                    setCampaignName(parse.query.utm_campaign)
                    setCampaignTerm(parse.query.utm_term)
                    setCampaignContent(parse.query.utm_content)
               } else {
                    setShowUTM("none");
                    setShowSubModal("none")
                    document.getElementById("url").style.marginBottom = "24px"
                    setCampaignSource('')
                    setCampaignMedium('')
                    setCampaignName('')
                    setCampaignTerm('')
                    setCampaignContent('')
               }
          } else {
               setShowUTM("none");
               setShowSubModal("none")
               document.getElementById("url").style.marginBottom = "24px"
          }
     }

     const onChangeUTM = (e, type) => {
          const order = appConfig.UTM;
          const parse = queryString.parseUrl(originLink.value);
          e.persist()
          clearTimeout(timeout_id);
          timeout_id = setTimeout(() => {
               switch (type) {
                    case "SOURCE":
                         if (campaignSource != String(e.target.value).trim()) {
                              setCampaignSource(String(e.target.value).trim());
                              if (parseUrl(originLink.value)) {
                                   if (e.target.value) {
                                        parse.query.utm_source = e.target.value;
                                   } else {
                                        delete parse.query.utm_source;
                                   }
                                   const newOriginLink = queryString.stringifyUrl(parse, { sort: (itemLeft, itemRight) => order.indexOf(itemLeft) - order.indexOf(itemRight) });
                                   setOriginLink({ value: newOriginLink });
                              }
                         }
                         break;
                    case "MEDIUM":
                         if (campaignMedium != String(e.target.value).trim()) {
                              setCampaignMedium(String(e.target.value).trim())
                              if (parseUrl(originLink.value)) {
                                   if (e.target.value) {
                                        parse.query.utm_medium = e.target.value;
                                   } else {
                                        delete parse.query.utm_medium;
                                   }
                                   const newOriginLink = queryString.stringifyUrl(parse, { sort: (itemLeft, itemRight) => order.indexOf(itemLeft) - order.indexOf(itemRight) });
                                   setOriginLink({ value: newOriginLink })
                              }
                         }
                         break;
                    case "NAME":
                         if (campaignName != String(e.target.value).trim()) {
                              setCampaignName(String(e.target.value).trim());
                              if (parseUrl(originLink.value)) {
                                   if (e.target.value) {
                                        parse.query.utm_campaign = e.target.value;
                                   } else {
                                        delete parse.query.utm_campaign;
                                   }
                                   const newOriginLink = queryString.stringifyUrl(parse, { sort: (itemLeft, itemRight) => order.indexOf(itemLeft) - order.indexOf(itemRight) });
                                   setOriginLink({ value: newOriginLink })
                              }
                         }
                         break;
                    case "TERM":
                         if (campaignTerm != String(e.target.value).trim()) {
                              setCampaignTerm(String(e.target.value).trim());
                              if (parseUrl(originLink.value)) {
                                   if (e.target.value) {
                                        parse.query.utm_term = e.target.value;
                                   } else {
                                        delete parse.query.utm_term;
                                   }
                                   const newOriginLink = queryString.stringifyUrl(parse, { sort: (itemLeft, itemRight) => order.indexOf(itemLeft) - order.indexOf(itemRight) });
                                   setOriginLink({ value: newOriginLink })
                              }
                         }
                         break;
                    case "CONTENT":
                         if (campaignContent != String(e.target.value).trim()) {
                              setCampaignContent(String(e.target.value).trim());
                              if (parseUrl(originLink.value)) {
                                   if (e.target.value) {
                                        parse.query.utm_content = e.target.value;
                                   } else {
                                        delete parse.query.utm_content;
                                   }
                                   const newOriginLink = queryString.stringifyUrl(parse, { sort: (itemLeft, itemRight) => order.indexOf(itemLeft) - order.indexOf(itemRight) });
                                   setOriginLink({ value: newOriginLink })
                              }
                         }
                         break;
                    default:
                         break;
               }
          }, time2);
     }

     const emitChangeDebounce = (value) => {
          if (parseUrl(value)) {
               dispatch(linkActions.generateSlash({ link: value }));
               dispatch(linkActions.generateInfo({ link: value }));
          }
     }

     const onCreate = () => {
          const expression = /[^A-Za-z0-9-_]/;
          const regex = new RegExp(expression);
          let slash = dataCreate.ladi_path;

          if (dataCreate && dataCreate.ladi_path == "") {
               setTimeout(() => { window.LadiUI.showInputMessage(document.getElementById("pathInput2"), true, true, i18n.t("Vui lòng nhập vào trường này")); }, 1);
               return;
          }

          if (slash.match(regex)) {
               setTimeout(() => { window.LadiUI.showInputMessage(document.getElementById("pathInput2"), true, true, i18n.t("Đường dẫn không hợp lệ")); }, 1);
               return;
          } else {
               // no code
          }

          if (tagRef.current) {
               const _tagIds = tagRef.current.getIDs() || [];
               setDataCreate({ ...dataCreate, tags: _tagIds });
          }

          dataCreate.tags = tagRef.current ? tagRef.current.getData() : [];
          dispatch(linkActions.create(dataCreate, callback));
          window.LadiUI.closeModal('modalCreateLink');
          setDataCreate({
               description: "",
               domain: domain,
               image: "",
               ladi_path: "",
               origin_link: "",
               tags: [],
               title_link: "",
          })
          setOriginLink({ value: "" })
     }

     useEffect(() => {
          handleChange(originLink.value)
          document.getElementById("url").value = originLink.value
          emitChangeDebounce(originLink.value);
          dispatch(domainActions.list());
     }, [originLink])

     useEffect(() => {
          setDataCreate({
               description: generateInfo && generateInfo.description ? generateInfo.description : "",
               domain: dataCreate.domain,
               image: generateInfo ? generateInfo.image : "",
               ladi_path: typeSlash && typeSlash.path ? typeSlash.path : typeSlash && typeSlash.slash ? typeSlash.slash : "",
               origin_link: originLink ? originLink.value : "",
               tags: [],
               title_link: generateInfo ? generateInfo.title : "",
          })
     }, [typeSlash, generateInfo, originLink])

     useEffect(() => {
          if (campaignSource == "" || campaignSource == undefined) {
               document.getElementById("campaignSource").value = "";
          } else {
               document.getElementById("campaignSource").value = campaignSource;
          }
          if (campaignMedium == "" || campaignMedium == undefined) {
               document.getElementById("campaignMedium").value = "";
          } else {
               document.getElementById("campaignMedium").value = campaignMedium;
          }
          if (campaignName == "" || campaignName == undefined) {
               document.getElementById("campaignName").value = "";
          } else {
               document.getElementById("campaignName").value = campaignName;
          }
          if (campaignTerm == "" || campaignTerm == undefined) {
               document.getElementById("campaignTerm").value = "";
          } else {
               document.getElementById("campaignTerm").value = campaignTerm;
          }
          if (campaignContent == "" || campaignContent == undefined) {
               document.getElementById("campaignContent").value = "";
          } else {
               document.getElementById("campaignContent").value = campaignContent;
          }
     }, [campaignSource, campaignMedium, campaignName, campaignTerm, campaignContent])

     return (
          <div className="ladiui modal" id="modalCreateLink" >
               <div className="ladiui modal-dialog modal-dialog-centered custom-ldp-width">
                    <div className="ladiui modal-content custom-ldp">
                         <div className="ladiui modal-header custom-ldp">
                              <div className="ladiui title-text-form-lever1">{i18n.t('Tạo Link')}</div>
                              <button type="button" className="ladiui modal-close" data-dismiss="modal" data-target="modalCreateLink">
                                   <i className="ladiui icon icon-ldp-close"></i>
                              </button>
                         </div>
                         <div className="ladiui form-group-custom">
                              <label className="ladiui title-text-form-lever2">{i18n.t("Link gốc")}</label>
                              <input
                                   id="url"
                                   autoComplete="off"
                                   name="name"
                                   type="text"
                                   className="ladiui form-control custom-ladilink"
                                   placeholder={i18n.t("Nhập Link gốc của bạn")}
                                   onInput={e => delayTimeOut(e)}
                              />
                              <div className="ladiui select-search dropdown" id="dropdown-staff">
                                   <div className="ladiui dropdown-toggle"
                                        id="dropdownMenuButton4" data-toggle="dropdown"
                                        style={showUTM ? { display: showUTM, fontSize: '12px', weight: '400', lineHeight: '16px', color: '#1C00C2', position: 'absolute', top: '-25px', right: '-490px', cursor: 'pointer', } : { display: 'none' }}>
                                        Tạo UTM
                                   </div>
                                   <ul className="ladiui dropdown-menu custom-people field">
                                        <li className="ladiui search-group custom-people">
                                             <div className="ladiui" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gridGap: '16px', marginTop: '16px' }}>
                                                  <div style={{ width: '100%' }}>
                                                       <div className='ladiui title-sub-ladilink'>Campaign Source</div>
                                                       <input id="campaignSource" className="ladiui setting form-control" placeholder="facebook, google, tiktok" onChange={e => onChangeUTM(e, "SOURCE")}></input>
                                                  </div>
                                                  <div style={{ width: '100%' }}>
                                                       <div className='ladiui title-sub-ladilink'>Campaign Medium</div>
                                                       <input id="campaignMedium" className="ladiui setting form-control" placeholder="search, display, remarketing" onChange={e => onChangeUTM(e, "MEDIUM")}></input>
                                                  </div>
                                             </div>
                                             <div className="ladiui" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gridGap: '16px', marginTop: '28px' }}>
                                                  <div style={{ width: '100%' }}>
                                                       <div className='ladiui title-sub-ladilink'>Campaign Name</div>
                                                       <input id="campaignName" className="ladiui setting form-control" placeholder="Campaign Name" onChange={e => onChangeUTM(e, "NAME")}></input>
                                                  </div>
                                                  <div style={{ width: '100%' }}>
                                                       <div className='ladiui title-sub-ladilink'>URL Campaign Term</div>
                                                       <input id="campaignTerm" className="ladiui setting form-control" placeholder="Campaign Term" onChange={e => onChangeUTM(e, "TERM")}></input>
                                                  </div>
                                             </div>
                                             <div className="ladiui" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gridGap: '16px', marginTop: '28px' }}>
                                                  <div style={{ width: '100%' }}>
                                                       <div className='ladiui title-sub-ladilink'>Campaign Content</div>
                                                       <input id="campaignContent" className="ladiui setting form-control" placeholder="Campaign Content" onChange={e => onChangeUTM(e, "CONTENT")}></input>
                                                  </div>
                                                  <div style={{ width: '100%' }}></div>
                                             </div>
                                        </li>
                                   </ul>
                              </div>
                         </div>

                         <div style={showSubModal ? { display: showSubModal } : { display: 'none' }}>
                              <div className='ladiui flex-row custom-flex-switch'>
                                   <div className="ladiui form-group-custom">
                                        <div className='ladiui'>
                                             <label className="ladiui title-text-form-lever2">{i18n.t("Tên miền")}</label>
                                             <div className="ladiui btn-group fixed-width">
                                                  <div className="ladiui dropdown">
                                                       <button id='package' className="ladiui btn btn-outline-light dropdown-toggle  select-dropdown fixed-width" data-toggle="dropdown">
                                                            <i></i>
                                                            <span className="ladiui dropdown-text">{dataCreate.domain}</span>
                                                       </button>
                                                       <ul className="ladiui dropdown-menu fixed-width">
                                                            <li onClick={() => setDataCreate({ ...dataCreate, domain: "ldp.ink" })}>
                                                                 <div className="ladiui dropdown-item">{i18n.t("ldp.ink")}</div>
                                                            </li>
                                                            {
                                                                 domains && domains.length > 0 ?
                                                                      domains.map(item => {
                                                                           return item.is_verified ? <li onClick={() => setDataCreate({ ...dataCreate, domain: item.domain })}><div className="ladiui dropdown-item">{item.domain}</div></li>: <></>;
                                                                      })
                                                                      :
                                                                      <></>
                                                            }
                                                       </ul>
                                                  </div>
                                             </div>
                                        </div>
                                   </div>

                                   <div className="ladiui form-group-custom">
                                        <div className='ladiui '>
                                             <label className="ladiui title-text-form-lever2">{i18n.t("Đường dẫn")}</label>
                                             <div className="ladiui btn-group fixed-width">
                                                  <input
                                                       id="pathInput2"
                                                       autoComplete="off"
                                                       name="name"
                                                       type="text"
                                                       className="ladiui form-control"
                                                       placeholder={i18n.t("Nhập đường dẫn")}
                                                       value={dataCreate && dataCreate.ladi_path ? dataCreate.ladi_path : ""}
                                                       onChange={e => setDataCreate({ ...dataCreate, ladi_path: e.target.value })} />
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              <div className="ladiui form-group-custom">
                                   <label className="ladiui title-text-form-lever2">{i18n.t("Tag")}</label>
                                   <Tag allTags={tags} ref={tagRef} />
                              </div>

                              <div className="ladiui modal-footer custom-ldp-decentralization">
                                   <div></div>
                                   <div className="ladiui footer-modal-btn">
                                        <button className="ladiui btn small txt body3 btn-secondary" data-dismiss="modal" data-target="modalCreateLink">
                                             {i18n.t("Huỷ bỏ")}
                                        </button>
                                        <button className="ladiui btn small txt body3 btn-primary" onClick={() => onCreate()}>
                                             {i18n.t("Tạo Link")}
                                        </button>
                                   </div>
                              </div>
                         </div>

                    </div>
               </div>
          </div >
     )
}

export default ModalCreateLink